import {
  MutableRefObject,
  useEffect,
  useState,
} from 'react';

type UseScrollObserverProps = {
  ref: MutableRefObject<HTMLDivElement | null>;
  threshold: number;
  rootMargin?: string;
  continuallyObserve?: boolean
};

function useScrollObserver({
  ref,
  threshold,
  rootMargin = '0px',
  continuallyObserve = false,
}: UseScrollObserverProps) {
  const [isVisible, setIsVisible] = useState(false);
  let scrollObserver: IntersectionObserver;

  useEffect(() => {
    if (ref?.current) {
      scrollObserver = new IntersectionObserver(
        (entries) => {
          const [first] = entries;

          if (first.isIntersecting && !continuallyObserve) {
            if (ref.current) {
              scrollObserver.unobserve(ref.current);
            }
          }

          setIsVisible(first.isIntersecting);
        },
        {
          threshold,
          rootMargin,
        },
      );

      scrollObserver.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        scrollObserver.unobserve(ref.current);
      }
    };
  }, []);

  return isVisible;
}

export default useScrollObserver;
