import { motion } from 'framer-motion';
import React, { useRef } from 'react';
import styled from 'styled-components';
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import 'swiper/css';

import useScrollObserver from '../../../hooks/useScrollObserver';

const ImageHeight = 600;
const ImageHeightTablet = 400;
const ImageHeightMobile = 300;

const SupportedBusinessesContainer = styled.div`
  background: ${(props) => props.theme.colors.White};
  background-image: url(/assets/dashed-looping-graphic.svg);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: contain;
  margin-bottom: 180px;
  padding-top: 180px;
  overflow: hidden;
  position: relative;

  @media (max-width: ${(props) => props.theme.breakpoints.Tablet}) {
    margin-bottom: 90px;
    padding: 90px 32px 0px 32px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.Mobile}) {
    padding: 90px 16px 0px 16px;
  }
`;

const Header = styled.div`
  color: #071058;
  font-family: ${(props) => props.theme.fonts.grotesk};
  font-size: 50px;
  font-weight: 500;
  letter-spacing: -.5px;
  line-height: 102%;
  margin-bottom: 46px;
  margin-left: 144px;
  text-align: left;
  width: 100%;

  @media (max-width: ${(props) => props.theme.breakpoints.Laptop}) {
    font-size: 40px;
    margin-left: 0px;
    text-align: center;
  }
`;

const Image = styled(motion.div) <{ src: string }>`
  background-image: url("${(props) => props.src}");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 20px;
  cursor: grab;
  height: ${ImageHeight}px;
  opacity: 0;
  position: relative;
  width: 100%;

  &:last-of-type {
    margin-right: 0px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.Tablet}) {
    height: ${ImageHeightTablet}px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.Mobile}) {
    height: ${ImageHeightMobile}px;
  }
`;

const ImageDescription = styled.div`
  align-items: flex-start;
  background: rgba(64, 84, 237, 0.7);
  border-radius: 5px;
  bottom: 40px;
  box-shadow: 0px 9.76295px 28.4013px rgba(45, 49, 52, 0.04);
  color: ${(props) => props.theme.colors.White};
  display: flex;
  font-weight: 600;
  justify-content: flex-start;
  left: 40px;
  padding: 10px 20px;
  position: absolute;
  white-space: nowrap;

  @media (max-width: ${(props) => props.theme.breakpoints.Mobile}) {
    left: 20px;
  }
`;

const DescriptionLine = styled.div`
  background: #FF8666;
  height: 18px;
  margin-right: 10px;
  width: 2px;
`;

const Icon = styled.img`
  border-radius: 50%;
  bottom: calc(${ImageHeight}px / 2);
  cursor: pointer;
  position: absolute;
  transition: all .5s ease-in-out;
  z-index: 2;

  @media (max-width: ${(props) => props.theme.breakpoints.Tablet}) {
    bottom: calc(${ImageHeightTablet}px / 2);
    width: 75px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.Mobile}) {
    bottom: calc(${ImageHeightMobile}px / 2);
    width: 50px;
  }

  &:hover {
    box-shadow: 0px 11px 32px rgba(45, 49, 52, 0.2);
    transform: scale(1.1);
  }
`;

const PrevIcon = styled(Icon)`
  left: 32px;
  transform: rotate(180deg);

  &:hover {
    transform: rotate(180deg) scale(1.1);
  }
`;

const NextIcon = styled(Icon)`
  right: 32px;
`;

const images = [
  {
    src: '/assets/1-logistics.jpg',
    type: 'Logistics',
  },
  {
    src: '/assets/3-janitorial.jpg',
    type: 'Janitorial',
  },
  {
    src: '/assets/2-general-contracting.jpg',
    type: 'General Contracting',
  },
  {
    src: '/assets/5-catering.jpg',
    type: 'Catering',
  },
  {
    src: '/assets/6-hvac.jpg',
    type: 'HVAC',
  },
  {
    src: '/assets/7-electrical.jpg',
    type: 'Electrical',
  },
];

function SlidePrev() {
  const swiper = useSwiper();

  return (
    <PrevIcon
      onClick={() => swiper.slidePrev()}
      src="/assets/next-icon.svg"
    />
  );
}

function SlideNext() {
  const swiper = useSwiper();

  return (
    <NextIcon
      onClick={() => swiper.slideNext()}
      src="/assets/next-icon.svg"
    />
  );
}

function SupportedBusinesses() {
  const supportedBusinessesRef = useRef<HTMLDivElement>(null);
  const isVisible = useScrollObserver({
    ref: supportedBusinessesRef,
    threshold: 0,
    rootMargin: '400px 0px',
  });

  return (
    <SupportedBusinessesContainer ref={supportedBusinessesRef}>
      <Header>
        All types of businesses use
        <br />
        Mesa to accelerate receivables
      </Header>

      <Swiper
        spaceBetween={30}
        slidesPerView={3.3}
        breakpoints={{
          320: {
            spaceBetween: 20,
            slidesPerView: 1.5,
          },
          480: {
            spaceBetween: 20,
            slidesPerView: 1.5,
          },
          768: {
            spaceBetween: 30,
            slidesPerView: 2.3,
          },
          968: {
            spaceBetween: 30,
            slidesPerView: 2.5,
          },
          1200: {
            spaceBetween: 30,
          },
        }}
      >
        {images.map((image, idx) => (
          <SwiperSlide key={image.src}>
            <Image
              key={image.src}
              src={isVisible ? image.src : ''}
              whileInView={{
                opacity: [0, 1],
                transition: {
                  duration: 0.75,
                  delay: idx * 0.15,
                },
              }}
              viewport={{ once: true }}
            >
              <ImageDescription>
                <DescriptionLine />
                {image.type}
              </ImageDescription>
            </Image>
          </SwiperSlide>
        ))}

        <SlidePrev />
        <SlideNext />
      </Swiper>
    </SupportedBusinessesContainer>
  );
}

export default SupportedBusinesses;
