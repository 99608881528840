import { motion, useAnimation } from 'framer-motion';
import React, {
  ReactElement,
  useEffect,
  useRef,
} from 'react';
import styled from 'styled-components';

import useScrollObserver from '../../../hooks/useScrollObserver';
import Navigation from './common/Navigation';
import OverviewPage from './OverviewPage';

const DashboardContainer = styled(motion.div)`
  display: flex;
  justify-content: flex-start;
  height: 100%;
  opacity: 0;
  position: relative;

  @media (max-width: ${(props) => props.theme.breakpoints.Mobile}) {
    flex-direction: column;
  }
`;

const ScrollableContainer = styled.div`
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;

  @media (max-width: ${(props) => props.theme.breakpoints.Tablet}) {
    // add space for fixed mobile nav
    margin-top: 60px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.Mobile}) {
    // add space for fixed mobile nav
    margin-top: 24px;
  }
`;

const WidthContainer = styled.div<{ notificationsVisible: boolean }>`
  min-width: 875px;
  padding: 90px 52px 52px 52px;
  position: relative;
  ${(props) => props.notificationsVisible && 'padding-top: 124px'};

  @media (max-width: ${(props) => props.theme.breakpoints.Tablet}) {
    min-width: 0px;
    padding: 24px 16px;
  }
`;

function DashboardDemo(): ReactElement {
  const dashboardDemoRef = useRef<HTMLDivElement>(null);
  const scrollRef = useRef<HTMLDivElement>(null);

  const isVisible = useScrollObserver({ ref: dashboardDemoRef, threshold: 0.2 });
  const controls = useAnimation();

  useEffect(() => {
    if (isVisible) {
      controls.start({
        opacity: [0, 1],
      });
    }
  }, [isVisible]);

  return (
    <DashboardContainer
      id="dashboard"
      ref={dashboardDemoRef}
      animate={controls}
      transition={{
        type: 'spring',
        duration: 1,
      }}
    >
      <Navigation isVisible={isVisible} />

      <ScrollableContainer ref={scrollRef} id="dashboard-scrollable-container">
        <WidthContainer notificationsVisible={false}>
          <OverviewPage />
        </WidthContainer>
      </ScrollableContainer>
    </DashboardContainer>
  );
}

export default DashboardDemo;
