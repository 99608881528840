import { useEffect, useMemo } from 'react';
import { PromoCodes } from '@mesa-labs/mesa-api/dist/types';

import localStorage from '../utils/localStorage';
import useSubRoute from './useSubRoute';

export const PROMO_CODE_LOCAL_STORAGE_KEY = 'mesa:promo-code';

function usePromoCode() {
  const subRoute = useSubRoute();

  const promoCode = useMemo(() => {
    switch (subRoute) {
      case 'earlypay_legacy':
        return PromoCodes.NET30_195;
      case 'accelerate':
        return PromoCodes.NET30_22;
      case 'portal':
        return PromoCodes.PORTAL;
      case 'mars':
        return PromoCodes.MARS;
      default:
        return '';
    }
  }, [subRoute]);

  useEffect(() => {
    if (promoCode) {
      localStorage.setItem(PROMO_CODE_LOCAL_STORAGE_KEY, promoCode);
    }
  }, [promoCode]);

  return promoCode || localStorage.getItem(PROMO_CODE_LOCAL_STORAGE_KEY);
}

export default usePromoCode;
