import React, {
  ReactElement,
} from 'react';
import styled from 'styled-components';
import { Typography } from '@mesa-labs/mesa-ui';

import mock from './mock';

const InvoiceSummaryContainer = styled.div``;

const InvoiceSummaryCards = styled.div`
  display: flex;
  gap: 3%;
  justify-content: space-between;
  margin-bottom: 60px;

  & > div {
    flex-basis: 30.333%;
    flex-grow: 0;
    flex-shrink: 0;

    @media (max-width: ${(props) => props.theme.breakpoints.Tablet}) {
      margin-bottom: 16px;
      width: 100%;
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.Mobile}) {
    margin-bottom: 24px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.Tablet}) {
    flex-wrap: wrap;
  }
`;

const HeaderContainer = styled.div`
  align-items: flex-end;
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
`;

const InvoiceSummaryHeaderContainer = styled(HeaderContainer)`
  border-bottom: 1px solid #ECF0F4;
  padding-bottom: 18px;
`;

const InvoiceSummaryCardContainer = styled.div`
  background: ${(props) => props.theme.colors.White};
  border-radius: 12px;
  border: 1px solid #EEF2F6;
  box-shadow: -2px 6px 24px rgba(186, 198, 222, 0.08);
  box-sizing: border-box;
  padding: 30px 26px;
`;

const InvoiceCardTitle = styled(Typography.H2)`
  font-size: 24px;
  line-height: 38px;
`;

const InvoiceSummaryCardLabel = styled.div`
  color: #686C8B;
  font-size: 14px;
  line-height: 17px;
  margin-left: 6px;
  text-transform: uppercase;
`;

const InvoiceSummaryCardTotal = styled.div`
  color: ${(props) => props.theme.colors.Midnight};
  display: flex;
  font-family: ${(props) => props.theme.fonts.grotesk};
  font-size: 34px;
  font-weight: 500;
  line-height: 120%;
  margin: 8px 0px 38px 0px;
`;

const CurrencySymbol = styled.div`
  align-self: flex-start;
  color: ${(props) => props.theme.colors.Midnight};
  font-size: 19px;
  font-weight: 500;
  letter-spacing: -0.05em;
  line-height: 23px;
  margin-right: 4px;
  opacity: .3;
`;

const InvoiceSummaryCardBottom = styled.div`
  display: flex;
  justify-content: flex-start;

  & :nth-child(1) {
    margin-right: 70px;
  }
`;

const InvoiceSummaryCardGroup = styled.div`
  align-items: center;
  display: flex;

  & :nth-child(1), :nth-child(2) {
    margin-right: 0px;
  }
`;

const InvoiceSummaryCardNumber = styled.div`
  color: ${(props) => props.theme.colors.Midnight};
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
`;

type InvoiceSummaryCardProps = {
  amount: number;
  label: string;
  numInvoices: number;
};

const toFormattedAmount = (amount: number) => new Intl.NumberFormat(undefined, { minimumFractionDigits: 2 }).format(amount);

function InvoiceSummaryCard(props: InvoiceSummaryCardProps): ReactElement {
  const {
    amount,
    label,
    numInvoices: invoiceNum,
  } = props;

  return (
    <InvoiceSummaryCardContainer>
      <InvoiceSummaryCardLabel>{label}</InvoiceSummaryCardLabel>
      <InvoiceSummaryCardTotal>
        <CurrencySymbol>$</CurrencySymbol>
        {toFormattedAmount(amount)}
      </InvoiceSummaryCardTotal>

      <InvoiceSummaryCardBottom>
        <InvoiceSummaryCardGroup>
          <InvoiceSummaryCardNumber>{invoiceNum}</InvoiceSummaryCardNumber>
          <InvoiceSummaryCardLabel>Invoices</InvoiceSummaryCardLabel>
        </InvoiceSummaryCardGroup>
      </InvoiceSummaryCardBottom>
    </InvoiceSummaryCardContainer>
  );
}

function InvoiceSummary(): ReactElement {
  const allTimeTotals = mock.getAllTimeInvoiceTotals.response;

  return (
    <InvoiceSummaryContainer>
      <InvoiceSummaryHeaderContainer>
        <InvoiceCardTitle>Summary</InvoiceCardTitle>
      </InvoiceSummaryHeaderContainer>

      <InvoiceSummaryCards id="dashboard-tour-step-1">
        <InvoiceSummaryCard
          label="Total Income"
          amount={allTimeTotals.overallRevenueTotal}
          numInvoices={allTimeTotals.overallInvoiceCount}
        />

        <InvoiceSummaryCard
          label="Paid Invoices"
          amount={allTimeTotals.paidRevenueTotal}
          numInvoices={allTimeTotals.paidInvoiceCount}
        />

        <InvoiceSummaryCard
          label="Pending Invoices"
          amount={allTimeTotals.pendingRevenueTotal}
          numInvoices={allTimeTotals.pendingInvoiceCount}
        />
      </InvoiceSummaryCards>
    </InvoiceSummaryContainer>
  );
}

export default InvoiceSummary;
