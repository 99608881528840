export const createRequest = async (method: string, url: string, data = {}) => {
  const baseUrl = CONFIG.api.gatewayUrl;

  const response = await fetch(`${baseUrl}${url}`, {
    mode: 'cors',
    method,
    cache: 'no-cache',
    credentials: 'same-origin',
    ...(data && {
      body: JSON.stringify(data),
    }),
  });

  return response.json();
};

type CreateSignUpInquiryParams = {
  companyName: string;
  email: string;
  recaptchaToken: string;
  phoneNumber: string;
  name: string;
  partner: string;
};

export const createSignUpInquiry = async (data: CreateSignUpInquiryParams) => createRequest('POST', '/sign-up-inquiry', data);
