import { Typography } from '@mesa-labs/mesa-ui';
import React, {
  ReactElement,
  useEffect,
  useMemo,
  useState,
} from 'react';
import styled from 'styled-components';

import InvoiceSummary from './InvoiceSummary';
import InvoiceTable from './InvoiceTable';
import TableHeader from './TableHeader';

import mock from './mock';

const OverviewContainer = styled.div`
  background: ${(props) => props.theme.colors.White};
  flex-grow: 1;
  height: 100%;
  position: relative;
`;

const HeaderContainer = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 24px;
`;

const Header = styled(Typography.H2)`
  margin-bottom: 4px;
`;

const SubHeader = styled.div`
  color: rgba(29, 29, 29, 0.7);
  font-size: 16px;
  line-height: 24px;
`;

const DashboardHeaderContainer = styled(HeaderContainer)`
  margin-bottom: 60px;
`;

function OverviewPage(): ReactElement {
  const [activeTab, setActiveTab] = useState<'invoices' | 'payments'>('invoices');

  const [paymentsTotal, setPaymentsTotal] = useState(10);

  const vendor = mock.getVendor.response;

  const greeting = useMemo(() => `Welcome, ${vendor.name}`, [vendor]);

  const { data: searchedInvoicesRaw } = mock.getAllInvoices.response;
  const { total: currentPaymentsTotal } = mock.getAllCustomerBatchTransfers.response;

  const [searchedInvoices] = useState(searchedInvoicesRaw);

  useEffect(() => {
    if (currentPaymentsTotal && currentPaymentsTotal !== paymentsTotal) {
      setPaymentsTotal(currentPaymentsTotal);
    }
  }, [currentPaymentsTotal, paymentsTotal]);

  return (
    <OverviewContainer>
      <DashboardHeaderContainer>
        <Header>
          {greeting}
        </Header>

        <SubHeader>Track and manage your receivables</SubHeader>
      </DashboardHeaderContainer>

      <InvoiceSummary />

      <TableHeader
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />

      {activeTab === 'invoices' && (
        <InvoiceTable
          vendor={vendor}
          invoices={searchedInvoices}
          invoicesLoading={false}
        />
      )}
    </OverviewContainer>
  );
}

export default OverviewPage;
