import React, { useState } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import getTheme from '../../../theme';
import { FadeInRight } from '../../common/ScrollAnimations';
import { useAppContext } from '../../../contexts/AppContext';
import useSubRoute from '../../../hooks/useSubRoute';
import NavigationLogo from './NavigationLogo';

const MobileNavTop = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 16px;

  @media (max-width: ${(props) => props.theme.breakpoints.Tablet}) {
    padding: 16px 28px;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.Tablet}) {
    display: none;
  }
`;

const MobileNavIcon = styled.img`
  cursor: pointer;
`;

const MobileNavigationContainer = styled.div`
  background: linear-gradient(140.47deg, #4054ED 23.66%, #1B2CAC 66.6%);
  color: ${(props) => props.theme.colors.White};
  display: flex;
  flex-direction: column;
  height: 100vh;
  left: 0px;
  padding: 16px;
  position: absolute;
  top: 0px;
  width: 100vw;
  z-index: 105;
`;

const MobileNavigationTop = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  height: 50px;
  margin-bottom: 40px;
  width: 100%;
`;

const MobileNavigationLogo = styled.img`
  width: 175px;
`;

const UnbrandedMobileNavigationLogo = styled.img`
  width: 96px;
`;

const MobileNavigationClose = styled.img`
  width: 30px;
`;

const MobileNavigationLinks = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 8px;

  & > div {
    margin-bottom: 24px;
    text-align: left;
  }
`;

const MobileNavigationLink = styled.a`
  font-size: 28px;
  font-weight: 500;
  line-height: 130%;
`;

const MobileNavGlobalStyle = createGlobalStyle`
  // present scrolling when the mobile nav is open
  body {
    overflow: hidden;
  }
`;

function MobileNavigation() {
  const { brand, partnerId, program } = useAppContext();
  const [mobileNavOpen, setMobileNavOpen] = useState(false);
  const subRoute = useSubRoute();
  const theme = getTheme(brand);

  return (
    <>
      <MobileNavTop>
        <NavigationLogo />
        <MobileNavIcon
          src="/assets/menu-icon.svg"
          onClick={() => setMobileNavOpen(true)}
        />
      </MobileNavTop>

      {mobileNavOpen && (
        <MobileNavigationContainer>
          <MobileNavGlobalStyle />

          <MobileNavigationTop>
            {brand !== 'unbranded' && (
              <MobileNavigationLogo src={theme.dynamicContent.topNavLogo} />
            )}

            {brand === 'unbranded' && (
              <UnbrandedMobileNavigationLogo src={theme.dynamicContent.topNavLogo} />
            )}

            <MobileNavigationClose
              src="/assets/close-icon.svg"
              onClick={() => setMobileNavOpen(false)}
            />
          </MobileNavigationTop>

          <MobileNavigationLinks>
            <FadeInRight delay={0.1} duration={0.5}>
              <MobileNavigationLink href={`${CONFIG.ui.vendorUIUrl}/sign-in`} target="_blank">Sign In</MobileNavigationLink>
            </FadeInRight>

            {brand !== 'unbranded' && (
              <FadeInRight delay={0.2} duration={0.5}>
                <MobileNavigationLink
                  href={`${CONFIG.ui.vendorUIUrl}/faq?partnerId=${partnerId}${program ? `&programCode=${program}` : ''}${subRoute ? `&subRoute=${subRoute}` : ''}`}
                >
                  FAQ
                </MobileNavigationLink>
              </FadeInRight>
            )}
          </MobileNavigationLinks>
        </MobileNavigationContainer>
      )}
    </>
  );
}

export default MobileNavigation;
