import { Partners } from '@mesa-labs/mesa-api/dist/types';

export type Brand = 'jll' | 'newmark' | 'cbre' | 'cushwake' | 'tishman' | 'unbranded';

const isProduction = import.meta.env.MODE === 'production';
const unbrandedHostnames = new Set([
  'joinmesa.com',
  'www.joinmesa.com',
  'sandbox.joinmesa.com',
  'marketing.joinmesa.com',
  'earlypay.joinmesa.com',
  'marketing.sandbox.joinmesa.com',
  'earlypay.sandbox.joinmesa.com',
]);
export const getBrand = (): Brand => {
  if (unbrandedHostnames.has(window.location.hostname)) {
    return 'unbranded';
  }

  const variant = window.location.hostname.split('.')[0];

  // allow for overriding in non-production environments
  const qs = new URLSearchParams(window.location.search);
  const brandOverride = qs.get('brand');

  if (!isProduction && brandOverride) {
    return brandOverride as Brand;
  }

  // default to JLL in development mode if no brandOverride is set
  if (import.meta.env.MODE === 'development') {
    return 'jll';
  }

  return variant as Brand || 'jll';
};

export const getPartnerIdFromBrand = (brand: Brand): Partners | undefined => {
  switch (brand) {
    case 'newmark':
      return Partners.NEWMARK;
    case 'jll':
      return Partners.JLL;
    default:
      return undefined;
  }
};

const SUBDOMAINS = new Set(['jll', 'newmark']);

export const getSubdomain = (): string | undefined => {
  const possibleSubdomain = window.location.host.split('.')[0];
  // accounting for irrelevant subdomains like wwww.
  if (SUBDOMAINS.has(possibleSubdomain)) {
    return possibleSubdomain;
  }

  return undefined;
};

export const wait = (ms: number): Promise<void> => new Promise((resolve) => {
  setTimeout(() => {
    resolve();
  }, ms);
});
