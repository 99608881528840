import React, {
  ReactElement,
  ReactNode,
} from 'react';
import styled from 'styled-components';
import { StatusLabel, utils } from '@mesa-labs/mesa-ui';
import ServiceLabel from './ServiceLabel';

export enum SortDirection {
  ASCENDING = 'asc',
  DESCENDING = 'desc',
}

const TableContainer = styled.div`
  background: ${(props) => props.theme.colors.White};
  border: 1px solid #EEF2F6;
  border-radius: 14px;
  box-shadow: -2px 6px 24px rgba(186, 198, 222, 0.08);
  margin-bottom: 20px;
`;

const Columns = styled.div<{ numColumns: number, useAutoWidth?: boolean }>`
  border-bottom: 1px solid #EBEAED;
  color: #84818A;
  display: flex;
  justify-content: flex-start;
  gap: 8px;
  height: 100%;
  margin-bottom: 8px;
  padding: 32px 40px 11px 40px;
  text-transform: uppercase;

  & > div {
    width: ${(props) => {
    if (props.useAutoWidth) {
      return `calc(100%/${props.numColumns})`;
    }

    return '';
  }};
  }
`;

const Column = styled.div<{ width?: string, fontSize?: string }>`
  align-items: center;
  display: flex;
  font-weight: 500;
  font-size: ${(props) => props.fontSize || '14px'};
  white-space: nowrap;
  text-overflow: ellipsis;
  justify-content: flex-start;
  overflow: hidden;
  gap: 8px;
  line-height: 16px;
  width: ${(props) => (props.width ? `calc(${props.width})` : '100%')};
`;

const Rows = styled.div`
  width: 100%;
  padding: 0px 22px;
`;

const RowContainer = styled.div<{ open?: boolean }>`
  border-bottom: 1px solid #EBEAED;
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 12px 0px;
  overflow: hidden;
  width: 100%;

  ${(props) => props.open && (`
    border-bottom: none;
    border-radius: 8px;
    box-shadow: 0px 10px 12px rgba(45, 49, 52, 0.08);
  `)}

  &:last-of-type {
    border-bottom: none;
  }
`;

const AdditionalRowData = styled.div`
  background: #FDFDFD;
  border-top: 1px solid #F8F8F8;
`;

const Row = styled.div<{ active?: boolean; background?: string, status: string, fontSize?: string }>`
  align-items: center;
  background: ${(props) => props.background || props.theme.colors.White};
  color: ${(props) => props.theme.colors.Gray900};
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  font-weight: ${(props) => (props.active && '600') || '500'};
  font-size: ${(props) => props.fontSize || '16px'};
  line-height: 24px;
  padding: 18px;
  width: 100%;

  &:last-of-type {
    border-bottom: none;
  }
`;

const RowItem = styled.div<{ numcolumns: number, width?: string, color?: string }>`
  color: ${(props) => props.color};
  width: ${(props) => {
    if (!props.width) {
      return `calc(100%/${props.numcolumns})`;
    }

    return `calc(${props.width})`;
  }};

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const DefaultFlexContainer = styled.div`
  display: flex;
  align-items: flex-start;
`;

const DefaultEmptyContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
`;

export type ColumnType = 'service' | 'status' | 'text' | 'date' | 'datetime' | 'currency' | 'jsonblob';
export type ColumnProp = {
  key: string;
  label: string,
  width?: string,
  sortField?: string,
  type?: ColumnType
  placeholder?: string;
};

type RowProp = Record<string, any>;

type TableProps = {
  columns: ColumnProp[];
  conditionalRowStyling?: (row: Record<string, unknown>) => string | undefined;
  emptyComponent?: ReactElement;
  expandedRowData?: ReactNode[];
  expandedRows?: number[];
  headerFontSize?: string;
  onExpandedRowsChange?: (rows: number[]) => void;
  onRowClick?: (row: Record<string, unknown>) => void;
  rowFontSize?: string;
  rows: RowProp[] | readonly RowProp[];
};

function AnimatedTable(props: TableProps): ReactElement {
  const {
    columns,
    conditionalRowStyling,
    emptyComponent,
    expandedRowData,
    expandedRows,
    headerFontSize,
    onExpandedRowsChange,
    onRowClick,
    rowFontSize,
    rows,
  } = props;

  const numColumns = columns.length;

  const getColumnElement = (column: ColumnProp, row: RowProp) => {
    const value = row[column.key];
    const currency = row.currencyCode || row.currency;
    const formattedObj = JSON.stringify(value, null, 2);
    switch (column.type || 'text') {
      case 'service':
        return (
          <DefaultFlexContainer>
            <ServiceLabel
              label={(typeof value === 'object' && value.label) || value}
              status={(typeof value === 'object' && value.status) || value}
            />
          </DefaultFlexContainer>
        );
      case 'status':
        return (
          <DefaultFlexContainer>
            <StatusLabel
              label={(typeof value === 'object' && value.label) || value}
              status={(typeof value === 'object' && value.status) || value}
            />
          </DefaultFlexContainer>
        );
      case 'date':
        return (
          value ? new Date(value).toLocaleDateString() : utils.capitalize(column.placeholder)
        );
      case 'datetime':
        return (
          value && new Date(value).toLocaleDateString()
        );
      case 'currency':
        if (value === null || value === undefined) {
          return utils.capitalize(column.placeholder);
        }

        return (
          currency ? utils.formatCurrency(value, currency) : value.toString()
        );
      case 'jsonblob':
        return (<pre>{formattedObj}</pre>);
      default:
        return (
          value && value.toString()
        );
    }
  };

  const rowElements = rows.map((row, idx) => (
    <div
      key={row.id}
    >
      <RowContainer
        id={row.id}
        open={expandedRows?.includes(idx)}
      >
        <Row
          fontSize={rowFontSize}
          background={conditionalRowStyling ? conditionalRowStyling(row) : undefined}
          status={row.state}
          onClick={() => {
            if (!expandedRows) {
              return;
            }
            if (!expandedRows.includes(idx)) {
              onExpandedRowsChange?.([...expandedRows, idx]);
            } else {
              const copy = [...expandedRows];
              copy.splice(expandedRows.indexOf(idx), 1);
              onExpandedRowsChange?.(copy);
            }
          }}
        >
          {
            columns.map((column) => (
              <RowItem
                numcolumns={numColumns}
                width={column.width}
                onClick={() => (onRowClick?.(row))}
              >
                {
                  getColumnElement(column, row)
                }
              </RowItem>
            ))
          }
        </Row>

        {expandedRows?.includes(idx) && (
          <AdditionalRowData
            key={`row-${row.id}-additional-data`}
          >
            {expandedRowData?.[idx]}
          </AdditionalRowData>
        )}
      </RowContainer>
    </div>
  ));

  return (
    <TableContainer>
      {
        !!rows.length && (
          <>
            <Columns numColumns={numColumns} useAutoWidth={!columns.some((column) => column.width)}>
              {
                columns.map((column: ColumnProp) => (
                  <Column key={`column-${column.key}`} width={column.width} fontSize={headerFontSize}>
                    {column.label}
                  </Column>
                ))
              }
            </Columns>

            <Rows>
              {rowElements}
            </Rows>
          </>
        )
      }

      {!rows.length && emptyComponent}
    </TableContainer>
  );
}

AnimatedTable.defaultProps = {
  conditionalRowStyling: () => { },
  emptyComponent: <DefaultEmptyContainer>No results.</DefaultEmptyContainer>,
  expandedRowData: {},
  expandedRows: [],
  headerFontSize: undefined,
  onExpandedRowsChange: () => { },
  onRowClick: () => { },
  rowFontSize: undefined,
};

export default AnimatedTable;
