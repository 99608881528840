import React, {
  ReactElement,
  useRef,
} from 'react';
import { Typography } from '@mesa-labs/mesa-ui';
import { motion } from 'framer-motion';
import styled from 'styled-components';

import TableOptions from './TableOptions';

const TableTitles = styled.div`
  align-items: center;
  display: flex;
  gap: 40px;
`;

const TableTitleUnderline = styled(motion.div)`
  background: ${(props) => props.theme.colors.Cobalt};
  bottom: -3px;
  left: 0px;
  height: 3px;
  position: absolute;
  right: 0px;
`;

const TableTitle = styled(Typography.H2) <{ active: boolean }>`
  border-bottom: 3px solid transparent;
  color: ${(props) => (props.active && props.theme.colors.Black) || props.theme.colors.Black50};
  cursor: pointer;
  font-family: ${(props) => props.theme.fonts.grotesk};
  font-size: 24px;
  line-height: 38px;
  position: relative;
  transition: border-color 300ms ease-in-out, color 300ms ease-in-out;

  &:hover {
    border-color: #4054ed66;
    color: ${(props) => props.theme.colors.Black};
  }
`;

const TableTitleContainer = styled.div`
  align-items: center;
  border-bottom: 1px solid #ECF0F4;
  display: flex;
  font-size: 32px;
  font-weight: 500;
  justify-content: space-between;
  line-height: 40px;
  padding-bottom: 18px;
  margin-bottom: 28px;
  max-width: 1100px;
  z-index: 3;
`;

type TableHeaderProps = {
  activeTab: 'invoices' | 'payments';
  setActiveTab: (tab: 'invoices' | 'payments') => void;
};

function TableHeader({
  activeTab,
  setActiveTab,
}: TableHeaderProps): ReactElement {
  const invoiceButtonRef = useRef<HTMLDivElement>(null);

  return (
    <TableTitleContainer>
      <TableTitles id="overview-table-titles">
        <TableTitle
          active={activeTab === 'payments'}
          onClick={() => setActiveTab('payments')}
        >
          Payments
          {activeTab === 'payments' && <TableTitleUnderline />}
        </TableTitle>

        <TableTitle
          ref={invoiceButtonRef}
          active={activeTab === 'invoices'}
          onClick={() => setActiveTab('invoices')}
          id="overview-payment-table-title"
        >
          Invoices
          {activeTab === 'invoices' && <TableTitleUnderline />}
        </TableTitle>

      </TableTitles>

      <TableOptions
        onDownload={() => Promise.resolve(new Blob())}
        exportFileName={`${activeTab}.csv`}
      />

    </TableTitleContainer>
  );
}

export default TableHeader;
