import { useEffect, useMemo } from 'react';
import { ProgramCodes } from '@mesa-labs/mesa-api/dist/types';

import localStorage from '../utils/localStorage';
import useSubRoute from './useSubRoute';

export const REFERRAL_CODE_LOCAL_STORAGE_KEY = 'mesa:referral-code';

function useReferralCode() {
  const subRoute = useSubRoute();

  const referralCode = useMemo(() => {
    switch (subRoute) {
      case 'pay-on-approval':
        return ProgramCodes.POA;
      case 'net60':
        return ProgramCodes.NET60;
      case 'net30':
        return ProgramCodes.NET30;
      case 'on-demand':
        return ProgramCodes.OD;
      default:
        return '';
    }
  }, [subRoute]);

  useEffect(() => {
    if (referralCode) {
      localStorage.setItem(REFERRAL_CODE_LOCAL_STORAGE_KEY, referralCode);
    }
  }, [referralCode]);

  return referralCode || localStorage.getItem(REFERRAL_CODE_LOCAL_STORAGE_KEY);
}

export default useReferralCode;
