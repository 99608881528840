import React, { ReactElement, useRef, useState } from 'react';
import styled from 'styled-components';
import { Button, DownloadButton, useClickOutside } from '@mesa-labs/mesa-ui';

const ExportButtonContainer = styled.div`
  position: relative;
`;

const HiddenDownloadButton = styled.div`
  display: none;
`;

const ButtonContainer = styled.div``;

const DropdownItems = styled.div`
  background: ${(props) => props.theme.colors.White};
  border-radius: 8px;
  border: 1px solid #D0D5DD;
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.1);
  box-sizing: border-box;
  color: #344054;
  font-size: 14px;
  left: 0px;
  line-height: 20px;
  overflow: hidden;
  position: absolute;
  right: 0px;
  top: 54px;
  width: 184px;
  z-index: 15;
`;

const DropdownItem = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 12px 16px;

  &:hover {
    background: linear-gradient(0deg, #F9FAFC, #F9FAFC), #FFFFFF;
  }
`;

export enum ExportFormatLabel {
  MesaCsv = 'Mesa Default',
  Quickbooks3Column = 'Quickbooks 3 Column',
  Quickbooks4Column = 'Quickbooks 4 Column',
}

type ExportButtonProps = {
  onDownload: () => Promise<BlobPart | BlobPart[]>;
  fileName: string;
};

function ExportButton({
  onDownload,
  fileName,
}: ExportButtonProps): ReactElement {
  const [open, setOpen] = useState(false);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const downloadButtonRef = useRef<HTMLDivElement | null>(null);

  useClickOutside(containerRef, () => setOpen(false));

  return (
    <ExportButtonContainer ref={containerRef}>
      <HiddenDownloadButton>
        <DownloadButton
          text="Export CSV"
          data={onDownload}
          fileName={fileName}
          ref={downloadButtonRef}
        />
      </HiddenDownloadButton>

      <ButtonContainer
        onClick={() => setOpen(!open)}
      >
        <Button
          text="Export CSV"
          iconSrc="assets/download-icon.svg"
          onClick={() => setOpen(!open)}
        />
      </ButtonContainer>

      {open && (
        <DropdownItems>
          {Object.values(ExportFormatLabel).map((label) => (
            <DropdownItem
              key={label}
              onClick={async () => { }}
            >
              {label}
            </DropdownItem>
          ))}
        </DropdownItems>
      )}
    </ExportButtonContainer>
  );
}

export default ExportButton;
