import { theme } from '@mesa-labs/mesa-ui';
import { Brand } from '../utils';

export default (brand: Brand) => ({
  ...theme,
  dynamicContent: {
    topNavLogo: `/assets/top-nav-logo-${brand}.svg`,
    heroGraphicImage: `assets/hero-graphic-image-${brand === 'jll' ? 'jll' : 'unbranded'}.svg`,
    heroGraphicImageMobile: `assets/hero-graphic-image-mobile-${brand === 'jll' ? 'jll' : 'unbranded'}.svg`,
    phoneEmailImage: `assets/phone-email-image-${brand}.svg`,
    tabletOnboardingImage: `assets/tablet-onboarding-image-${brand}.svg`,
    tabletSignInImage: 'assets/tablet-sign-in-image.svg',
  },
});
