import { types } from '@mesa-labs/mesa-api';
import {
  BankAccountType,
  CountryCode,
  CurrencyCode,
  FeeScheme,
  ProductCodes,
  VendorLocale,
  VendorStatus,
} from '@mesa-labs/mesa-api/dist/types';

const setDate = (date: Date, days: number) => new Date(new Date(date).setDate(date.getDate() + days));

const serviceAgreementTemplate = `
<div class="service-agreement-container">
  <div class="header">Mesa Service Agreement</div>
  <div class="section">
    <div class="business-information-line">Business Information</div>
    <div class="line"> <b>Representative:</b> {{firstName}} {{lastName}} </div>
    <div class="line"> <b>Business Name:</b> {{businessName}} </div>
    <div class="line"> <b>Business Address:</b> <br /> {{address}} {{addressAdditional}}, {{city}} {{state}},
      {{zip}}
      <br />
      {{addressSecondLine}}
    </div>
    <div class="line"> <b>Date of this Agreement: </b> {{effectiveDate}} </div>
  </div>

  <div class="section">
    NOTE: This is a mock Mesa Service Agreement! 
  </div>
</div>
`;

const getOnboardingResponse: types.RedactedOnboardingResponse = {
  id: '1',
  parentId: '1',
  partnerId: 1,
  externalVendorId: '1',
  vendorName: 'Mesa',
  email: 'test@mesa.com',
  readOnly: true,
  feeScheme: FeeScheme.DISCOUNTING,
};

const today = new Date();

const redactedVendorResponse: types.RedactedVendorResponse = {
  id: '123456',
  name: 'Boulder HVAC',
  dba: 'Boulder HVAC DBA',
  address: '123 Test Street.',
  addressAdditional: '',
  city: 'Boulder',
  state: 'CO',
  zip: '80302',
  countryCode: CountryCode.US,
  title: 'title',
  firstName: 'Demo',
  lastName: 'User',
  email: 'demo@joinmesa.com',
  phoneNumber: '(123) 456-7890',
  providerStatus: 'pending',
  isActive: true,
  status: VendorStatus.ACTIVE,
  feeScheme: FeeScheme.DISCOUNTING,
  locale: VendorLocale.US,
  onboardingPartnerId: types.Partners.JLL,
  externalVendorIdentifiers: [],
  registeredAgent: 'Registered Agent',
  uploadedBankDocumentCount: 1,
  uploadedBusinessDocumentCount: 1,
  uploadedThirdPartyBusinessDocumentCount: 1,
  verifiedBankDocumentCount: 1,
  verifiedBusinessDocumentCount: 1,
  verifiedThirdPartyBusinessDocumentCount: 1,
  primaryAccountVerificationStatus: 'completed',
  hasAcceptedCurrentMsa: true,
  hasRequestedCurrentPsa: false,
  hasAcceptedCurrentPsa: false,
  createdAt: today.toString(),
};

const redactedVendorAccountResponse: types.RedactedVendorAccountAggregateResponse = {
  id: '123456',
  vendorId: '123456',
  verificationProcess: 'micro-transactions' as any,
  name: 'Boulder HVAC',
  bankAccountType: BankAccountType.CHECKING,
  maskedRoutingNumber: '1234',
  maskedAccountNumber: '4567',
  countryCode: CountryCode.US,
  currencyCode: CurrencyCode.USD,
  isPrimary: true,
  providerStatus: 'pending',
  verificationStatus: 'pending',
  verificationAttempts: 0,
  hasDirectDebitAuthorization: false,
  isHidden: false,
  createdAt: new Date().toISOString(),
  documents: [],
  documentStatus: 'approved' as any,
  isDebitOnly: false,
};

const vendorProgramResponse: types.VendorProgramResponse = {
  id: '123456',
  vendorId: '123456',
  productCode: types.ProductCodes.INVOICE_FINANCE,
  partnerId: types.Partners.JLL,
  programCode: types.ProgramCodes.NET30,
  discountRate: 0.025,
  netDays: 30,
  instantPayoutRiskPremium: 1,
  dailyDynamicDiscountRate: 1,
  supplementalRate: 0.035,
  createdAt: today.toString(),
  updatedAt: today.toString(),
  createdBy: 'System',
  updatedBy: 'System',
};

const redactedVendorProduct: types.RedactedVendorProductResponse = {
  vendorId: '123456',
  productCode: ProductCodes.INVOICE_FINANCE,
  effectivePrograms: [vendorProgramResponse],
};

const redactedVendorAggregateResponse: types.RedactedVendorAggregateResponse = {
  ...redactedVendorResponse,
  accounts: [redactedVendorAccountResponse],
  products: [redactedVendorProduct],
  userAccounts: [],
  features: {
    instantPayoutAlwaysOn: false,
  },
};

const redactedInvoiceResponse: types.IPagedResults<types.RedactedInvoiceResponse> = {
  data: [{
    id: '1',
    partnerId: 1,
    externalClientId: '1',
    externalVendorId: '1',
    externalDocumentId: '1',
    vendorName: 'Mesa',
    invoiceDate: today.toISOString(),
    estimatedPaymentDate: today.toISOString(),
    clientName: 'Acme Bank',
    invoiceNumber: '1242512',
    estimatedPaymentAmount: 4312,
    displayState: types.InvoiceDisplayState.PENDING,
    totalOpenAmount: 4312,
    totalEstimatedTaxAmount: 100,
    totalActualTaxAmount: 100,
    instantPayoutRequested: false,
    vendorLocale: VendorLocale.US,
    currencyCode: CurrencyCode.USD,
    batchPaymentReferences: [],
    onDemandPayoutRequested: false,
  } as any,
  {
    id: '3',
    partnerId: 1,
    externalClientId: '1',
    externalVendorId: '1',
    externalDocumentId: '1',
    vendorName: 'Mesa',
    invoiceDate: setDate(today, -30).toISOString(),
    estimatedPaymentDate: setDate(today, -30).toISOString(),
    clientName: 'Global Tech Co.',
    invoiceNumber: '1439061',
    estimatedPaymentAmount: 5923,
    displayState: types.InvoiceDisplayState.PENDING,
    totalOpenAmount: 5923,
    totalEstimatedTaxAmount: 100,
    totalActualTaxAmount: 100,
    instantPayoutRequested: false,
    vendorLocale: VendorLocale.US,
    currencyCode: CurrencyCode.USD,
    batchPaymentReferences: [],
    onDemandPayoutRequested: false,
  },
  {
    id: '4',
    partnerId: 1,
    externalClientId: '1',
    externalVendorId: '1',
    externalDocumentId: '1',
    vendorName: 'Mesa',
    invoiceDate: setDate(today, -60).toISOString(),
    estimatedPaymentDate: setDate(today, -60).toISOString(),
    clientName: 'Global Tech Co.',
    invoiceNumber: '141585',
    estimatedPaymentAmount: 5882,
    displayState: types.InvoiceDisplayState.PAID,
    totalOpenAmount: 5882,
    totalEstimatedTaxAmount: 100,
    totalActualTaxAmount: 100,
    instantPayoutRequested: false,
    vendorLocale: VendorLocale.US,
    currencyCode: CurrencyCode.USD,
    batchPaymentReferences: [],
    onDemandPayoutRequested: false,
  },
  {
    id: '5',
    partnerId: 1,
    externalClientId: '1',
    externalVendorId: '1',
    externalDocumentId: '1',
    vendorName: 'Mesa',
    invoiceDate: setDate(today, -90).toISOString(),
    estimatedPaymentDate: setDate(today, -90).toISOString(),
    clientName: 'Global Tech Co.',
    invoiceNumber: '141586',
    estimatedPaymentAmount: 5882,
    displayState: types.InvoiceDisplayState.PAID,
    totalOpenAmount: 5882,
    totalEstimatedTaxAmount: 100,
    totalActualTaxAmount: 100,
    instantPayoutRequested: false,
    vendorLocale: VendorLocale.US,
    currencyCode: CurrencyCode.USD,
    batchPaymentReferences: [],
    onDemandPayoutRequested: false,
  },
  {
    id: '6',
    partnerId: 1,
    externalClientId: '1',
    externalVendorId: '1',
    externalDocumentId: '1',
    vendorName: 'Mesa',
    invoiceDate: setDate(today, -120).toISOString(),
    estimatedPaymentDate: setDate(today, -120).toISOString(),
    clientName: 'Global Tech Co.',
    invoiceNumber: '141587',
    estimatedPaymentAmount: 5882,
    displayState: types.InvoiceDisplayState.PAID,
    totalOpenAmount: 5882,
    totalEstimatedTaxAmount: 100,
    totalActualTaxAmount: 100,
    instantPayoutRequested: false,
    vendorLocale: VendorLocale.US,
    currencyCode: CurrencyCode.USD,
    batchPaymentReferences: [],
    onDemandPayoutRequested: false,
  }],
  page: 1,
  limit: 10,
  total: 10,
};

const pendingInvoices = redactedInvoiceResponse.data.filter((item) => item.displayState === types.InvoiceDisplayState.PENDING);
const paidInvoices = redactedInvoiceResponse.data.filter((item) => item.displayState === types.InvoiceDisplayState.PAID);

const vendorBusinessTotalsResponse: types.VendorBusinessTotalsResponse = {
  vendorId: '1',
  overallRevenueTotal: redactedInvoiceResponse.data.reduce((total, item) => total + item.estimatedPaymentAmount, 0),
  overallInvoiceCount: redactedInvoiceResponse.data.length,
  pendingRevenueTotal: pendingInvoices.reduce((total, item) => total + item.estimatedPaymentAmount, 0),
  pendingInvoiceCount: pendingInvoices.length,
  paidRevenueTotal: paidInvoices.reduce((total, item) => total + item.estimatedPaymentAmount, 0),
  paidInvoiceCount: paidInvoices.length,
  eligibleRevenueTotal: 0,
  eligibleInvoiceCount: 0,
  eligibleAverageDaysOutstanding: 0,
};

const getAllCustomerBatchTransfersResponse: types.IPagedResults<types.RedactedCustomerBatchTransferResponse> = {
  data: [{
    vendorId: '1',
    batchTransferId: '17e1261d',
    batchAmount: 4312,
    transferInitiatedAt: today.toISOString(),
    transferDisplayState: types.TransferDisplayState.PENDING,
    batchPaymentReference: '1',
    type: types.TransferType.FUNDING,
    paymentAmount: 4312,
    debitAmount: 4312,
    currency: CurrencyCode.USD,
  },
  {
    vendorId: '1',
    batchTransferId: '27e1261d',
    batchAmount: 9172,
    transferInitiatedAt: setDate(today, -30).toISOString(),
    transferDisplayState: types.TransferDisplayState.PENDING,
    batchPaymentReference: '1',
    type: types.TransferType.FUNDING,
    paymentAmount: 4312,
    debitAmount: 4312,
    currency: CurrencyCode.USD,
  },
  {
    vendorId: '1',
    batchTransferId: '37e1261d',
    batchAmount: 5923,
    transferInitiatedAt: setDate(today, -60).toISOString(),
    transferDisplayState: types.TransferDisplayState.PAID,
    batchPaymentReference: '1',
    type: types.TransferType.FUNDING,
    paymentAmount: 4312,
    debitAmount: 4312,
    currency: CurrencyCode.USD,
  },
  {
    vendorId: '1',
    batchTransferId: '47e1261d',
    batchAmount: 5882,
    transferInitiatedAt: setDate(today, -90).toISOString(),
    transferDisplayState: types.TransferDisplayState.PAID,
    batchPaymentReference: '1',
    type: types.TransferType.FUNDING,
    paymentAmount: 4312,
    debitAmount: 4312,
    currency: CurrencyCode.USD,
  },
  {
    vendorId: '1',
    batchTransferId: '57e1261d',
    batchAmount: 5923,
    transferInitiatedAt: setDate(today, -120).toISOString(),
    transferDisplayState: types.TransferDisplayState.PAID,
    batchPaymentReference: '1',
    type: types.TransferType.FUNDING,
    paymentAmount: 4312,
    debitAmount: 4312,
    currency: CurrencyCode.USD,
  },
  {
    vendorId: '1',
    batchTransferId: '67e1261d',
    batchAmount: 4312,
    transferInitiatedAt: setDate(today, -150).toISOString(),
    transferDisplayState: types.TransferDisplayState.PAID,
    batchPaymentReference: '1',
    type: types.TransferType.FUNDING,
    paymentAmount: 4312,
    debitAmount: 4312,
    currency: CurrencyCode.USD,
  },
  {
    vendorId: '1',
    batchTransferId: '77e1261d',
    batchAmount: 9172,
    transferInitiatedAt: setDate(today, -180).toISOString(),
    transferDisplayState: types.TransferDisplayState.PAID,
    batchPaymentReference: '1',
    type: types.TransferType.FUNDING,
    paymentAmount: 4312,
    debitAmount: 4312,
    currency: CurrencyCode.USD,
  },
  {
    vendorId: '1',
    batchTransferId: '87e1261d',
    batchAmount: 5882,
    transferInitiatedAt: setDate(today, -210).toISOString(),
    transferDisplayState: types.TransferDisplayState.PAID,
    batchPaymentReference: '1',
    type: types.TransferType.FUNDING,
    paymentAmount: 4312,
    debitAmount: 4312,
    currency: CurrencyCode.USD,
  }],
  page: 1,
  limit: 2,
};

const createCustomerAccountLinkResponse = {
  verificationProviderToken: 'link-sandbox-18e3879b-f251-47c9-a209-83ef998baa48',
};

const getLatestServiceAgreementResposne: types.RedactedServiceAgreementResponse = {
  version: 1,
  htmlTemplate: serviceAgreementTemplate,
  active: true,
};

const confirmVerificationResponse: types.RedactedVendorAccountResponse = {
  ...redactedVendorAccountResponse,
  verificationStatus: 'succeeded',
};

export default {
  getOnboarding: {
    method: 'GET',
    url: /.*onboardings\/.*/,
    statusCode: 200,
    response: getOnboardingResponse,
  },
  getAllTimeInvoiceTotals: {
    method: 'GET',
    url: /.*vendors\/.*\/accounts/,
    statusCode: 200,
    response: vendorBusinessTotalsResponse,
  },
  getVendor: {
    method: 'GET',
    url: /.*vendors\/.*/,
    statusCode: 200,
    response: redactedVendorAggregateResponse,
  },
  getAllInvoices: {
    method: 'GET',
    url: /.*invoices\/.*/,
    statusCode: 200,
    response: redactedInvoiceResponse,
  },
  getAllCustomerBatchTransfers: {
    method: 'GET',
    url: /.*customer-batch-transfers.*/,
    statusCode: 200,
    response: getAllCustomerBatchTransfersResponse,
  },
  getPlaidLinkToken: {
    method: 'POST',
    url: /.*customers\/accounts\/link/,
    statusCode: 201,
    response: createCustomerAccountLinkResponse,
  },
  getLatestServiceAgreement: {
    method: 'POST',
    statusCode: 201,
    url: /.*service-agreements\/versions\/latest/,
    response: getLatestServiceAgreementResposne,
  },
  createExternalVendor: {
    method: 'PUT',
    statusCode: 201,
    url: /.*partners\/.*\/external-vendors\/.*/,
    response: redactedVendorResponse,
  },
  createUnassignedExternalVendor: {
    method: 'POST',
    statusCode: 201,
    url: /.*vendors.*/,
    response: redactedVendorResponse,
  },
  createSecondaryBankAccount: {
    method: 'POST',
    statusCode: 201,
    url: /.*vendors\/.*\/accounts.*/,
    response: redactedVendorAccountResponse,
  },
  confirmVerification: {
    method: 'POST',
    statusCode: 201,
    url: /.*vendors\/.*\/accounts\/.*\/verify/,
    response: confirmVerificationResponse,
  },
};
