import React from 'react';
import ReactDOM from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import mixpanel from 'mixpanel-browser';

import App from './components/App';
import { AppContextProvider } from './contexts/AppContext';

Sentry.init({
  dsn: CONFIG.sentry.dsn,
  environment: import.meta.env.MODE,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: CONFIG.sentry.tracesSampleRate,
  normalizeDepth: 5,
});

mixpanel.init(CONFIG.mixpanel.token, { debug: true });

const root = ReactDOM.createRoot(document.querySelector('#root')!);

document.addEventListener('DOMContentLoaded', () => {
  const subdomain = window.location.hostname.split('.')[0];

  const indexSubdomains = ['partners', 'joinmesa', 'jll', 'marketing'];

  if (!indexSubdomains.includes(subdomain)) {
    const metaTag = document.createElement('meta');
    metaTag.name = 'robots';
    metaTag.content = 'noindex';
    document.head.appendChild(metaTag);
  }
});

root.render(
  <AppContextProvider>
    <App />
  </AppContextProvider>,
);
