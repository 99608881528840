import { motion } from 'framer-motion';
import React from 'react';
import styled from 'styled-components';
import getTheme from '../../../theme';
import { useAppContext } from '../../../contexts/AppContext';

const HeroAnimationContainer = styled.div`
  position: relative;

  @media (max-width: ${(props) => props.theme.breakpoints.Laptop}) {
    display: none;
  }
`;

const HeroGraphicContainer = styled(motion.div) <{ top: string; left: string, zIndex?: number }>`
  position: absolute;
  left: ${(props) => props.left};
  top: ${(props) => props.top};
  z-index: ${(props) => props.zIndex || 10};
`;

const HeroGraphic = styled.img<{ width?: string }>`
  width: ${(props) => props.width || 'auto'};

  @media (max-width: ${(props) => props.theme.breakpoints.Tablet}) {
    width: 100%;
  }
`;

const LineContainer = styled.div`
  left: -84px;
  position: absolute;  
  top: -60px;
  z-index: 11;
`;

const dashboardTransition = {
  type: 'tween',
  duration: 0.5,
};

const dashboardContainerVariants = {
  show: {
    transition: {
      staggerChildren: 0.4,
    },
  },
};

const dashboardMainVariants = {
  hidden: { opacity: 0, y: 60 },
  show: { opacity: 1, y: 0 },
};

const dashboardCardVariants = {
  hidden: {
    opacity: 0,
    y: 50,
    skewX: 5,
    skewY: 5,
  },
  show: {
    opacity: 1,
    y: 0,
    skewX: 0,
    skewY: 0,
  },
};

function HeroAnimation() {
  const { brand } = useAppContext();
  const theme = getTheme(brand);

  return (
    <HeroAnimationContainer>
      <motion.div
        variants={dashboardContainerVariants}
        initial="hidden"
        animate="show"
      >
        <HeroGraphicContainer
          top="0px"
          left="0%"
          variants={dashboardMainVariants}
          transition={dashboardTransition}
        >
          <HeroGraphic src={theme.dynamicContent.heroGraphicImage} />
        </HeroGraphicContainer>

        <HeroGraphicContainer
          top="-30px"
          left="0%"
          zIndex={13}
          variants={dashboardCardVariants}
          transition={dashboardTransition}
        >
          <HeroGraphic src="/assets/dashboard-card-1.svg" />
        </HeroGraphicContainer>

        <HeroGraphicContainer
          top="0px"
          left="40%"
          zIndex={12}
          variants={dashboardCardVariants}
          transition={dashboardTransition}
        >
          <HeroGraphic src="/assets/dashboard-card-2.svg" />
        </HeroGraphicContainer>

        <HeroGraphicContainer
          top="80px"
          left="78%"
          zIndex={10}
          variants={dashboardCardVariants}
          transition={dashboardTransition}
        >
          <HeroGraphic src="/assets/dashboard-card-3.svg" />
        </HeroGraphicContainer>

        <LineContainer>
          <motion.svg width="1112" height="383" viewBox="0 0 1112 383" fill="none" xmlns="http://www.w3.org/2000/svg">
            <motion.path
              initial={{ opacity: 0 }}
              animate={{ opacity: 0.5 }}
              opacity="0.5"
              d="M4.2268 320.261V320.261C-10.442 180.131 165.822 106.388 255.31 215.216L336.656 314.144C418.637 413.843 575.714 397.837 635.899 283.65L705.303 151.973C755.774 56.2173 856.992 -1.79228 965.126 3.06538L1111 9.61842"
              stroke="url(#paint_linear)"
              strokeWidth="5"
              transition={{
                ...dashboardTransition,
                duration: 1,
                delay: 2.75,
              }}
            />
            <defs>
              <linearGradient id="paint_linear" x1="1055.14" y1="11.1193" x2="-75.6832" y2="402.609" gradientUnits="userSpaceOnUse">
                <stop offset="0.0250645" stopColor="white" stopOpacity="0" />
                <stop offset="0.159807" stopColor="white" />
                <stop offset="0.742937" stopColor="white" />
                <stop offset="0.899888" stopColor="white" stopOpacity="0" />
              </linearGradient>
            </defs>
          </motion.svg>
        </LineContainer>
      </motion.div>
    </HeroAnimationContainer>
  );
}

export default HeroAnimation;
