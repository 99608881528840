import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const FooterContainer = styled.div`
  background: #071058;
  border-radius: 20px;
  color: ${(props) => props.theme.colors.White};
  font-size: 18px;
  line-height: 32px;
  width: 100%;

  @media (max-width: ${(props) => props.theme.breakpoints.Tablet}) {
    border-radius: 0px;
    font-size: 16px;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 60px;

  @media (max-width: ${(props) => props.theme.breakpoints.Laptop}) {
    padding: 45px;
  }
`;

const Top = styled.div`
  margin-bottom: 120px;
  width: 50%;

  @media (max-width: ${(props) => props.theme.breakpoints.Laptop}) {
    margin-bottom: 50px;
    width: 100%;
  }
`;

const Bottom = styled.div`
  align-items: flex-end;
  display: flex;
  flex-wrap: wrap;
  font-size: 17px;
  justify-content: space-between;
  line-height: 32px;

  @media (max-width: ${(props) => props.theme.breakpoints.Laptop}) {
    font-size: 16px;
    line-height: 26px;
  }
`;

const BottomRight = styled.div`
  flex-wrap: wrap;
  text-align: right;

  @media (max-width: ${(props) => props.theme.breakpoints.Laptop}) {
    flex-basis: 100%;
    text-align: left;
  }
`;

const LegalLinks = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;

  @media (max-width: ${(props) => props.theme.breakpoints.Laptop}) {
    align-items: flex-start;
    flex-direction: column;
    margin-bottom: 50px;
  }
`;

const LegalLink = styled(Link)`
  cursor: pointer;
  margin-right: 36px;

  &:hover {
    text-decoration: underline;
  }

  &:last-of-type {
    margin-right: 0px;
  }
`;

const Logo = styled.img`
  width: 75px;
`;

const AddressContainer = styled.div`
  margin-bottom: 42px;
`;

const CopyrightContainer = styled.div``;

function Footer() {
  const date = (new Date()).getUTCFullYear();

  return (
    <FooterContainer>
      <ContentContainer>
        <Top>
          Mesa is a technology provider of electronic invoicing and invoice
          financing solutions. Our state-of-the-art platform helps buyers provide
          a safe and automated accelerated payment solution to suppliers and
          vendors across all verticals. Headquartered in Boulder, Colorado, our
          vision is to help businesses get paid sooner, easier.
        </Top>

        <Bottom>
          <LegalLinks>
            <LegalLink to="/legal/electronic-consent">Electronic Consent</LegalLink>
            <LegalLink to="/legal/terms-of-service">Terms of Use</LegalLink>
            <LegalLink to="/legal/privacy-policy">Privacy Policy</LegalLink>
          </LegalLinks>

          <BottomRight>
            <Logo src="/assets/mesa-white.svg" />

            <AddressContainer>
              1905 15th St. #338
              <br />
              Boulder, CO 80306
            </AddressContainer>

            <CopyrightContainer>
              &#169; Mesa Financial, Inc.
              {' '}
              {date}
            </CopyrightContainer>
          </BottomRight>
        </Bottom>
      </ContentContainer>
    </FooterContainer>
  );
}

export default Footer;
