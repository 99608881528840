import React, { useRef } from 'react';
import styled from 'styled-components';
import useScrollObserver from '../../../hooks/useScrollObserver';
import getTheme from '../../../theme';
import { FadeInDown, FadeInRight, FadeInUp } from '../../common/ScrollAnimations';
import { useAppContext } from '../../../contexts/AppContext';

const AchLegacyContainer = styled.div`
  align-items: center;
  background: #4054ED;
  border-radius: 20px;
  display: grid;
  grid-template-columns: 50%;
  grid-template-rows: 546px;
  grid-column-gap: 24px;
  justify-items: center;
  margin-bottom: 180px;
  padding: 0px 90px;
  position: relative;
  width: 100%;

  @media (max-width: ${(props) => props.theme.breakpoints.Tablet}) {
    border-radius: 0px;
    grid-template-columns: 100%;
    grid-template-rows: auto;
    margin-bottom: 90px;
    padding: 24px 32px;
    overflow-y: hidden;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.Mobile}) {
    padding: 0px 32px;
  }
`;

const Header = styled.div`
  color: ${(props) => props.theme.colors.White};
  font-family: ${(props) => props.theme.fonts.grotesk};
  font-size: 50px;
  font-weight: 500;
  letter-spacing: -.5px;
  line-height: 102%;
  margin-bottom: 35px;

  @media (max-width: ${(props) => props.theme.breakpoints.Tablet}) {
    font-size: 48px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.Mobile}) {
    font-size: 40px;
  }
`;

const Copy = styled.div`
  color: #FFFFFF;
  font-size: 22px;
  line-height: 160%;
  max-width: 520px;
`;

const Left = styled.div`
  position: relative;

  @media (max-width: ${(props) => props.theme.breakpoints.Tablet}) {
    padding: 40px 0px 0px 0px;
  }
`;

const Right = styled.div`
  grid-column: 2;

  @media (max-width: ${(props) => props.theme.breakpoints.Tablet}) {
    grid-column: 1;
    transform: translateY(100px);
  }
`;

const Graphic = styled.img`
  position: absolute;
  height: 100%;
  left: 0px;
  opacity: .1;
  top: 10px;
`;

const MesaMobileGraphic = styled.img``;

const HiddenBottom = styled.div`
  background: ${(props) => props.theme.colors.White};
  z-index: 11;
  bottom: -160px;
  height: 160px;
  position: absolute;
  right: 0px;
  width: 100%;

  @media (max-width: ${(props) => props.theme.breakpoints.Tablet}) {
    height: 80px;
    bottom: -80px;
  }
`;

function AchLegacy() {
  const achLegacyRef = useRef<HTMLDivElement>(null);
  const isVisible = useScrollObserver({
    ref: achLegacyRef,
    threshold: 0,
  });
  const { brand } = useAppContext();
  const theme = getTheme(brand);

  return (
    <AchLegacyContainer ref={achLegacyRef}>
      <Graphic src="/assets/ach-legacy-graphic.svg" />
      <Left>
        <FadeInDown>
          <Header>
            ACH
            {' '}
            {'>'}
            {' '}
            Legacy Payments
          </Header>
        </FadeInDown>

        <FadeInRight>
          <Copy>
            No more waiting for checks to arrive in the mail, no more manually
            processing credit card payments.
          </Copy>
        </FadeInRight>
      </Left>

      <Right>
        {isVisible && (
          <FadeInUp>
            <MesaMobileGraphic src={theme.dynamicContent.phoneEmailImage} />
          </FadeInUp>
        )}
      </Right>

      <HiddenBottom />
    </AchLegacyContainer>
  );
}

export default AchLegacy;
