import { motion } from 'framer-motion';
import styled from 'styled-components';

export const GradientIcon = styled.img<{ width: number; height: number }>`
  background: linear-gradient(52.3deg, rgba(64, 84, 237, 0.2) -10.57%, rgba(64, 84, 237, 0) 112.43%);
  border-radius: 10px;
  height: ${(props) => props.height}px;
  margin-right: 22px;
  padding: 16px;
  width: ${(props) => props.width}px;

  @media (max-width: ${(props) => props.theme.breakpoints.Mobile}) {
    margin-right: 16px;
  }
`;

export const GradientIconContainer = styled(motion.div) <{ width: number; height: number; padding: number }>`
  align-items: center;
  background: linear-gradient(52.3deg, rgba(64, 84, 237, 0.2) -10.57%, rgba(64, 84, 237, 0) 112.43%);
  border-radius: 10px;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  height: ${(props) => props.height}px;
  margin-right: 22px;
  overflow: hidden;
  padding: ${(props) => props.padding}px;
  width: ${(props) => props.width}px;

  @media (max-width: ${(props) => props.theme.breakpoints.Mobile}) {
    margin-right: 16px;
  }
`;
