import React from 'react';
import styled from 'styled-components';

import { useAppContext } from '../../../contexts/AppContext';
import getTheme from '../../../theme';

const NavigationLogoContainer = styled.div``;

const Logo = styled.img`
  @media (max-width: ${(props) => props.theme.breakpoints.Desktop}) {
    width: 200px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.Laptop}) {
    width: 175px;
  }
`;

const UnbrandedLogo = styled.img`
  width: 96px;
`;

function NavigationLogo() {
  const { brand } = useAppContext();
  const theme = getTheme(brand);

  return (
    <NavigationLogoContainer>
      {brand !== 'unbranded' && (
        <Logo src={theme.dynamicContent.topNavLogo} />
      )}

      {brand === 'unbranded' && (
        <UnbrandedLogo src={theme.dynamicContent.topNavLogo} />
      )}
    </NavigationLogoContainer>
  );
}

export default NavigationLogo;
