import { useMemo } from 'react';
import { ProgramCodes, PromoCodes } from '@mesa-labs/mesa-api/dist/types';
import { Brand } from '../utils';

export const PROMO_CODE_LOCAL_STORAGE_KEY = 'mesa:promo-code';

type UseAchNumDaysProps = {
  brand: Brand;
  referralCode: string | null;
  promoCode: string | null;
};

function useAchNumDays({ brand, referralCode, promoCode }: UseAchNumDaysProps) {
  const numDays = useMemo(() => {
    if (brand === 'jll') {
      switch (referralCode) {
        case ProgramCodes.NET60:
          return '60';
        case ProgramCodes.NET30:
          return '30';
        default:
        // fall through to promoCode
      }
      switch (promoCode) {
        case PromoCodes.NET30_195:
        case PromoCodes.NET30_22:
        default:
          return '30';
      }
    } else if (brand === 'newmark') {
      return '15';
    } else {
      return '30';
    }
  }, [referralCode, promoCode]);

  return numDays;
}

export default useAchNumDays;
