import React from 'react';
import styled from 'styled-components';
import { utils } from '@mesa-labs/mesa-ui';

type Service = 'Invoice Service' | 'Payment Service' | 'Vendor Service';

const ServiceColors: Record<string, any> = {
  'Invoice Service': {
    backgroundColor: 'rgba(32, 201, 172, 0.1)',
    color: '#20C9AC',
  },
  'Payment Service': {
    backgroundColor: 'rgba(252, 52, 0, 0.1)',
    color: '#FC3400',
  },
  'Vendor Service': {
    backgroundColor: 'rgba(200, 32, 220, 0.1)',
    color: '#007FFF',
  },
  default: {
    backgroundColor: 'rgba(242, 242, 242, 1)',
    color: '#84818A',
  },
};

const ServiceLabelContainer = styled.div<{ service: Service }>`
  background-color: ${(props) => (ServiceColors[props.service] || ServiceColors.default).backgroundColor};
  border-radius: 4px;
  color: ${(props) => (ServiceColors[props.service] || ServiceColors.default).color};
  font-size: 14px;
  padding: 4px 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

type StatusLabelProps = {
  label: string;
  status: string;
};

function ServiceLabel(props: StatusLabelProps): React.ReactElement {
  const { label, status } = props;

  return (
    <ServiceLabelContainer service={status as Service}>
      {utils.capitalize(label)}
    </ServiceLabelContainer>
  );
}

export default ServiceLabel;
