import { motion } from 'framer-motion';
import React, { ReactElement } from 'react';
import styled from 'styled-components';

const AnimatedContainer = styled(motion.div)``;

type AnimationProps = {
  children: ReactElement | ReactElement[];
  duration?: number;
  delay?: number;
  style?: Record<string, unknown>;
  type?: 'tween' | 'spring' | 'intertia';
};

export function FadeInUp({
  children,
  duration = 1,
  delay = 0,
  style = {},
  type = 'tween',
}: AnimationProps) {
  return (
    <AnimatedContainer
      initial={{ opacity: 0, y: 15 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration, delay, type }}
      viewport={{ once: true }}
      style={style}
    >
      {children}
    </AnimatedContainer>
  );
}
FadeInUp.defaultProps = {
  duration: 1,
  delay: 0,
  style: {},
  type: 'tween',
};

export function FadeInDown({
  children,
  duration = 1,
  delay = 0,
  style = {},
  type = 'tween',
}: AnimationProps) {
  return (
    <AnimatedContainer
      initial={{ opacity: 0, y: -15 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration, delay, type }}
      viewport={{ once: true }}
      style={style}
    >
      {children}
    </AnimatedContainer>
  );
}
FadeInDown.defaultProps = {
  duration: 1,
  delay: 0,
  style: {},
  type: 'tween',
};

export function FadeInRight({
  children,
  duration = 1,
  delay = 0,
  style = {},
  type = 'tween',
}: AnimationProps) {
  return (
    <AnimatedContainer
      initial={{ opacity: 0, x: -15 }}
      whileInView={{ opacity: 1, x: 0 }}
      transition={{ duration, delay, type }}
      viewport={{ once: true }}
      style={style}
    >
      {children}
    </AnimatedContainer>
  );
}
FadeInRight.defaultProps = {
  duration: 1,
  delay: 0,
  style: {},
  type: 'tween',
};
