import React, { ReactElement, useMemo, useState } from 'react';
import { RedactedInvoiceResponse, RedactedVendorResponse } from '@mesa-labs/mesa-api/dist/types';
import styled from 'styled-components';
import {
  LoadingSpinner,
  Pagination,
  PaginationDropdown,
  utils,
} from '@mesa-labs/mesa-ui';

import AnimatedTable from './common/AnimatedTable';

const InvoiceTableContainer = styled.div`
  @media (max-width: ${(props) => props.theme.breakpoints.Tablet}) {
    display: none;
  }
`;

const TableContainer = styled.div``;

const PaginationLimit = styled.div`
  align-items: center;
  align-self: flex-end;
  display: flex;
  margin-right: 12px;
`;

const PaginationText = styled.div`
  font-size: 13px;
  font-weight: 500;
  margin-right: 8px;
`;

const PaginationContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
`;

const InvoiceDetails = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  padding: 18px;
`;

const InvoiceDetail = styled.div``;

const InvoiceDetailLabel = styled.div`
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 4px;
`;

const InvoiceDetailValue = styled.div`
  font-size: 13px;
  margin-bottom: 12px;

  &:last-of-type {
    margin-bottom: 0px;
  }
`;

const InvoicesLoadingSpinner = styled(LoadingSpinner)`
  padding: 80px;
`;

type InvoiceTableProps = {
  vendor: RedactedVendorResponse | undefined;
  invoices: readonly RedactedInvoiceResponse[];
  invoicesLoading: boolean;
};

function InvoiceTable(props: InvoiceTableProps): ReactElement {
  const {
    vendor,
    invoices,
    invoicesLoading,
  } = props;

  const [expandedRows, setExpandedRows] = useState<number[]>([]);

  const [page, setPage] = useState(1);
  const [total] = useState(10);
  const [limit] = useState({ key: '10', value: 10 });

  const totalPages = Math.ceil((total || 0) / limit.value) || 1;

  const invoiceRows = useMemo(() => invoices.map((invoice) => ({
    ...invoice,
    currency: 'USD',
  })), [vendor, invoices]);

  const expandedRowData = invoices.map((invoice) => (
    <InvoiceDetails key={`invoice-details-${invoice.id}`}>
      <InvoiceDetail>
        <InvoiceDetailLabel>Invoiced At</InvoiceDetailLabel>
        <InvoiceDetailValue>{utils.formatDate(invoice.invoiceDate)}</InvoiceDetailValue>
      </InvoiceDetail>

      <InvoiceDetail>
        <InvoiceDetailLabel>Payment Date</InvoiceDetailLabel>
        <InvoiceDetailValue>{invoice.estimatedPaymentDate ? utils.formatDate(invoice.estimatedPaymentDate) : ''}</InvoiceDetailValue>
      </InvoiceDetail>
    </InvoiceDetails>
  ));

  return (
    <InvoiceTableContainer id="dashboard-tour-step-2">
      <TableContainer>
        {invoicesLoading && <InvoicesLoadingSpinner />}
        {!invoicesLoading && (
          <AnimatedTable
            columns={[
              {
                key: 'invoiceNumber',
                label: 'Invoice No.',
                width: '25%',
              },
              {
                key: 'displayState',
                label: 'Status',
                type: 'status',
                width: '25%',
              },
              {
                key: 'totalOpenAmount',
                label: 'Invoice Amount',
                type: 'currency',
                width: '25%',
              },
              {
                key: 'estimatedPaymentDate',
                label: 'Payment Date',
                type: 'date',
                width: '25%',
              },
            ]}
            rows={invoiceRows}
            expandedRowData={expandedRowData}
            expandedRows={expandedRows}
            onExpandedRowsChange={(rows) => setExpandedRows(rows)}
          />
        )}
      </TableContainer>

      <PaginationContainer>
        <PaginationLimit>
          <PaginationText>
            Results per page:
          </PaginationText>

          <PaginationDropdown
            limits={[]}
            onChange={() => { }}
            selectedLimit={limit}
          />
        </PaginationLimit>

        <Pagination
          currentPage={page}
          totalPages={totalPages}
          onPrev={() => {
            if (page - 1 < 1) {
              return;
            }

            setPage(page - 1);
          }}
          onNext={() => {
            if (page + 1 > totalPages) {
              return;
            }

            setPage(page + 1);
          }}
        />
      </PaginationContainer>

    </InvoiceTableContainer>
  );
}

export default InvoiceTable;
