import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Legal from '../components/legal';
import TermsOfService from '../components/legal/TermsOfService';
import PrivacyPolicy from '../components/legal/PrivacyPolicy';
import ElectronicConsent from '../components/legal/ElectronicConsent';
import Home from '../components/home';

function AppRoutes() {
  return (
    <Routes>
      <Route
        path="/"
        element={<Home />}
      />

      <Route
        path="/legal"
        element={<Legal />}
      />

      <Route
        path="/legal/terms-of-service"
        element={<TermsOfService />}
      />

      <Route
        path="/legal/privacy-policy"
        element={<PrivacyPolicy />}
      />

      <Route
        path="/legal/electronic-consent"
        element={<ElectronicConsent />}
      />

      <Route
        path="/*"
        element={<Home />}
      />
    </Routes>
  );
}

export default AppRoutes;
