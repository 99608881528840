import React, {
  ReactElement,
  useEffect,
  useState,
} from 'react';
import styled from 'styled-components';
import { Typography } from '@mesa-labs/mesa-ui';
import { motion, useAnimation } from 'framer-motion';

import NavigationFooter from './NavigationFooter';

const NavigationContainer = styled(motion.div)`
  align-items: center;
  background-color: ${(props) => props.theme.colors.Midnight};
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  display: none;
  flex-direction: column;
  flex-shrink: 0;
  justify-content: space-between;
  padding: 32px 16px;
  width: 0px;
  z-index: 4;

  @media (min-width: ${(props) => props.theme.breakpoints.Tablet}) {
    display: flex;
  }
`;

const NavLinkTop = styled.div`
  width: 100%;
`;

const LogoContainer = styled(motion.div)`
  align-self: flex-start;
  margin-left: 8px;
`;

const Logo = styled.img<{ open: boolean }>`
  margin: 14px 0px 16px 0px;
  padding-bottom: 40px;
  transform: translateX(${(props) => (props.open && '100%') || '0%'}) scale(0.9);
  z-index: 1;
`;

const NavlinkContainer = styled.div<{ open: boolean }>`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  position: relative;
  width: 100%;
`;

const Navlink = styled(motion.a) <{ active: boolean }>`
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  height: 48px;
  justify-content: flex-start;
  padding: 14px 18px;
  margin-bottom: 8px;
  width: 100%;

  ${(props) => props.active && `
    background: ${props.theme.colors.Cobalt};
    border-radius: 6px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  `}

  &:last-of-type {
    margin-bottom: 0px;
  }
`;

const NavlinkText = styled(Typography.BodyMediumBold)`
  color: ${(props) => props.theme.colors.White};
  margin-left: 16px;
`;

const NavlinkImage = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  width: 24px;
`;

const NavigationFooterContainer = styled.div`
  width: 100%;
`;

type NavigationProps = {
  isVisible: boolean;
};

function Navigation({ isVisible }: NavigationProps): ReactElement {
  const [open, setOpen] = useState(false);

  const mainControls = useAnimation();
  const fadeInControls = useAnimation();

  useEffect(() => {
    if (!isVisible) {
      return;
    }

    mainControls.start({
      opacity: 1,
      width: 250,
      transition: {
        duration: 1,
        type: 'spring',
      },
    });

    fadeInControls.start((i) => ({
      opacity: [0, 1],
      translateX: [-30, 0],
      transition: {
        delay: i * 0.1,
        type: 'spring',
        duration: 0.8,
      },
    }));
  }, [isVisible]);

  return (
    <NavigationContainer id="side-nav" animate={mainControls}>
      <LogoContainer animate={fadeInControls} custom={0}>
        <Logo open={open} src="/assets/mesa-white.svg" />
      </LogoContainer>

      <NavlinkContainer open={open}>
        <NavLinkTop>
          <Navlink
            animate={fadeInControls}
            custom={1}
            onClick={() => setOpen(false)}
            active
            href="/dashboard"
          >
            <NavlinkImage>
              <img src="assets/home.svg" alt="Home Icon" />
            </NavlinkImage>
            <NavlinkText>Home</NavlinkText>
          </Navlink>

          <Navlink
            animate={fadeInControls}
            custom={2}
            onClick={() => setOpen(false)}
            active={false}
            href="/dashboard/account"
          >
            <NavlinkImage>
              <img src="assets/account.svg" alt="Account Icon" />
            </NavlinkImage>
            <NavlinkText>Account</NavlinkText>
          </Navlink>

          <Navlink
            animate={fadeInControls}
            custom={3}
            id="dashboard-tour-step-3"
            onClick={() => setOpen(false)}
            active={false}
            href="/dashboard/support"
          >
            <NavlinkImage>
              <img src="assets/dashboard.svg" alt="Dashboard Icon" />
            </NavlinkImage>
            <NavlinkText>Support</NavlinkText>
          </Navlink>
        </NavLinkTop>

        <NavigationFooterContainer>
          <NavigationFooter />
        </NavigationFooterContainer>
      </NavlinkContainer>
    </NavigationContainer>
  );
}

export default Navigation;
