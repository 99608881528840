import React from 'react';
import { useValidation } from '@mesa-labs/mesa-ui';
import styled from 'styled-components';

import useGetOnboardingLink from '../../../hooks/useGetOnboardingLink';
import { useAppContext } from '../../../contexts/AppContext';
import { REFERRAL_CODE_LOCAL_STORAGE_KEY } from '../../../hooks/useReferralCode';
import { PROMO_CODE_LOCAL_STORAGE_KEY } from '../../../hooks/usePromoCode';
import NavigationLogo from './NavigationLogo';

const DesktopNavigationContainer = styled.div`
  align-items: center;
  display: none;
  justify-content: space-between;
  padding: 0px 114px;
  width: 100%;

  @media (max-width: ${(props) => props.theme.breakpoints.Desktop}) {
    padding: 0px 80px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.Laptop}) {
    padding: 0px 50px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.Tablet}) {
    padding: 16px 28px;
    margin-bottom: 60px;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.Tablet}) {
    display: flex;
  }
`;

const Links = styled.div`
  align-items: center;
  display: flex;
  gap: 52px;
  margin-left: auto;

  @media (max-width: ${(props) => props.theme.breakpoints.Tablet}) {
    display: none;
  }
`;

const Link = styled.a`
  font-size: 18px;
  line-height: 100%;
  color: #DFE3FF;
  transition: all .25s ease-in-out;

  &:hover {
    color: #FEFEFE;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.Laptop}) {
    font-size: 17px;
  }
`;

const HeaderCTA = styled.div`
  align-items: center;
  background: ${(props) => props.theme.colors.White};
  border-radius: 41px;
  border: 2px solid #4054ED;
  color: #4054ED;
  cursor: pointer;
  display: flex;
  font-size: 18px;
  line-height: 160%;
  padding: 10px 24px;
  transition: background 200ms ease-in-out;

  &:hover {
    background: linear-gradient(0deg, #F9FAFC, #F9FAFC), #FFFFFF;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.Tablet}) {
    display: none;
  }
`;

type NavigationProps = {
  subRoute: string;
  setSignUpInquiryModalOpen: (open: boolean) => void;
};

function DesktopNavigation({ subRoute, setSignUpInquiryModalOpen }: NavigationProps) {
  const { partnerId, program, brand } = useAppContext();

  const params = new URLSearchParams(window.location.search);
  const onboardingId = params.get('onboardingId') || '';
  const { results: validationResults } = useValidation({ onboardingId });
  const getOnboardingLink = useGetOnboardingLink({ onboardingId: (validationResults.onboardingId?.isValid && onboardingId) || '' });

  return (
    <DesktopNavigationContainer>
      <NavigationLogo />

      <Links>
        {
          brand !== 'unbranded' && (
            <Link href={`${CONFIG.ui.vendorUIUrl}/faq?partnerId=${partnerId}${subRoute ? `&subRoute=${subRoute}` : ''}${program ? `&programCode=${program}` : ''}`}>
              FAQ
            </Link>
          )
        }

        <Link href={`${CONFIG.ui.vendorUIUrl}/sign-in?partnerId=${partnerId}${subRoute ? `&subRoute=${subRoute}` : ''}`}>
          Sign In
        </Link>

        {brand !== 'unbranded' && (
          <HeaderCTA
            onClick={() => {
              localStorage.removeItem(REFERRAL_CODE_LOCAL_STORAGE_KEY);
              localStorage.removeItem(PROMO_CODE_LOCAL_STORAGE_KEY);
              window.location.assign(getOnboardingLink());
            }}
          >
            Get Started
          </HeaderCTA>
        )}

        {brand === 'unbranded' && (
          <HeaderCTA onClick={() => setSignUpInquiryModalOpen(true)}>Get Demo</HeaderCTA>
        )}
      </Links>
    </DesktopNavigationContainer>
  );
}

export default DesktopNavigation;
