import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import {
  Input,
  Button,
  useValidation,
} from '@mesa-labs/mesa-ui';
import { types as api } from '@mesa-labs/mesa-api';

import localStorage from '../../../utils/localStorage';
import useReferralCode, { REFERRAL_CODE_LOCAL_STORAGE_KEY } from '../../../hooks/useReferralCode';
import { PROMO_CODE_LOCAL_STORAGE_KEY } from '../../../hooks/usePromoCode';
import cypressSelectors from '../../../../cypress-selectors.json';
import { useAppContext } from '../../../contexts/AppContext';
import GetStartedButton from './GetStartedButton';
import useGetOnboardingLink from '../../../hooks/useGetOnboardingLink';

const SignUpCardContainer = styled.div`
  background: ${(props) => props.theme.colors.White};
  border-radius: 0px 4px 4px rgb(0 0 0 / 17%);
  border-radius: 11px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 24px;
  padding: 22px;
  width: 403px;

  @media (max-width: ${(props) => props.theme.breakpoints.Tablet}) {
    margin: 0px auto 52px auto;
    width: 360px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.Laptop}) {
    position: static;
    margin: 0px 0px 16px 0px;
    transform: none;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.Mobile}) {
    width: auto;
  }
`;

const SignUpCardForm = styled.div`
  display: flex;
  flex-direction: column;
`;

const SignUpCardHeader = styled.div`
  color: ${(props) => props.theme.colors.Midnight};
  font-family: ${(props) => props.theme.fonts.grotesk};
  font-size: 28px;
  font-weight: 600;
  line-height: 39px;
  margin-bottom: 8px;
`;

const SubmitButtonContainer = styled.div`
  margin-top: 8px;
`;

const GetStartedButtonContainer = styled.div`
  margin-bottom: 32px;  
`;

function GetStartedCard() {
  const params = new URLSearchParams(window.location.search);
  const [businessName, setBusinessName] = useState(params.get('businessName') || '');
  const onboardingId = params.get('onboardingId') || '';
  const referralCode = useReferralCode();
  const {
    brand,
    program,
    signUpCardABTestBucket,
  } = useAppContext();

  const trimmedBusinessName = businessName.trim();

  const { results: validationResults } = useValidation({
    onboardingId,
    name: businessName,
  });

  const canSubmit = useMemo(() => {
    // only allow sign up redirect on the JLL, Newmark, and POA (Pay On Approval) marketing sites
    if (brand !== 'jll' && brand !== 'newmark' && (program !== api.ProgramCodes.POA && referralCode === api.ProgramCodes.POA)) {
      return false;
    }

    switch (signUpCardABTestBucket) {
      case 'BUSINESS_NAME_ONLY':
        return validationResults.name?.isValid;
      case 'NO_INFO_REQUIRED':
      default:
        return true;
    }
  }, [
    brand,
    signUpCardABTestBucket,
    validationResults.onboardingId,
    validationResults.name,
  ]);

  const getOnboardingLink = useGetOnboardingLink({
    businessName: trimmedBusinessName,
    onboardingId: (validationResults.onboardingId?.isValid && onboardingId) || '',
  });

  const onGetStartedClick = () => {
    if (!canSubmit) {
      return;
    }

    localStorage.removeItem(REFERRAL_CODE_LOCAL_STORAGE_KEY);
    localStorage.removeItem(PROMO_CODE_LOCAL_STORAGE_KEY);
    window.location.assign(getOnboardingLink());
  };

  return (
    <>
      {signUpCardABTestBucket !== 'NO_INFO_REQUIRED' && (
        <SignUpCardContainer>
          <SignUpCardForm>
            <SignUpCardHeader>Get Started</SignUpCardHeader>

            <Input
              dataCy={cypressSelectors.businessNameInput}
              type="text"
              value={businessName}
              onChange={(evt) => setBusinessName(evt.target.value)}
              validationResult={validationResults.name}
              label="Business Name"
            />

            <SubmitButtonContainer>
              <Button
                dataCy={cypressSelectors.signUpSubmitButton}
                onClick={onGetStartedClick}
                text="Continue"
                color="primary"
                width="100%"
              />
            </SubmitButtonContainer>
          </SignUpCardForm>
        </SignUpCardContainer>
      )}

      {signUpCardABTestBucket === 'NO_INFO_REQUIRED' && (
        <GetStartedButtonContainer>
          <GetStartedButton onClick={onGetStartedClick} dataCy={cypressSelectors.signUpSubmitButton} />
        </GetStartedButtonContainer>
      )}
    </>
  );
}

export default GetStartedCard;
