import React, { useRef } from 'react';
import styled from 'styled-components';
import useScrollObserver from '../../../hooks/useScrollObserver';
import getTheme from '../../../theme';
import { FadeInDown, FadeInRight, FadeInUp } from '../../common/ScrollAnimations';
import { useAppContext } from '../../../contexts/AppContext';

const EasyOnboardingContainer = styled.div<{ unbranded: boolean }>`
  align-items: center;
  background: ${(props) => props.theme.colors.White};
  border: 2px solid rgba(7, 16, 88, 0.12);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0px auto;
  overflow-y: hidden;
  margin-bottom: ${(props) => (props.unbranded && 80) || 0}px;
  padding: 80px;
  position: relative;
  text-align: center;
  width: 100%;

  @media (max-width: ${(props) => props.theme.breakpoints.Tablet}) {
    margin-bottom: ${(props) => (props.unbranded && 40) || 0}px;
    padding: 32px;
  }
`;

const Header = styled.div`
  color: #071058;
  font-family: ${(props) => props.theme.fonts.grotesk};
  font-size: 50px;
  font-weight: 500;
  letter-spacing: -.5px;
  line-height: 102%;
  margin-bottom: 25px;
  text-align: center;
  width: 100%;

  @media (max-width: ${(props) => props.theme.breakpoints.Tablet}) {
    font-size: 40px;
  }
`;

const Copy = styled.div`
  color: #071058;
  font-size: 18px;
  line-height: 28px;
  margin-bottom: 60px;
  max-width: 585px;
`;

const MesaTabletGraphic = styled.img`
  margin-bottom: -100px;
  width: 100%;

  @media (min-width: ${(props) => props.theme.breakpoints.Tablet}) {
  margin-bottom: -200px;
  }
`;

const GradientOverlay = styled.div`
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%);
  bottom: 0px;
  height: 30%;
  left: 0px;
  position: absolute;
  width: 100%;
  z-index: 10;
`;

function EasyOnboarding() {
  const { brand } = useAppContext();
  const onboardingRef = useRef<HTMLDivElement>(null);
  const isVisible = useScrollObserver({
    ref: onboardingRef,
    threshold: 0,
  });
  const theme = getTheme(brand);

  return (
    <EasyOnboardingContainer ref={onboardingRef} unbranded={brand === 'unbranded'}>
      <FadeInDown>
        <Header>Easy Onboarding</Header>
      </FadeInDown>

      <FadeInRight>
        <Copy>
          Traditional financing options can bury you in paperwork.
          {' '}
          Mesa simplifies enrollment to a few clicks so you can be on your way
          {' '}
          to financing your next invoice for early payment.
        </Copy>
      </FadeInRight>

      {isVisible && (
        <FadeInUp>
          <MesaTabletGraphic src={theme.dynamicContent.tabletOnboardingImage} />
        </FadeInUp>
      )}
      <GradientOverlay />
    </EasyOnboardingContainer>
  );
}

export default EasyOnboarding;
