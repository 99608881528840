import React, { useMemo } from 'react';
import styled from 'styled-components';
import { GradientIcon } from '../../common/GradientIcon';
import { useAppContext } from '../../../contexts/AppContext';

const BenefitsContainer = styled.div`
  align-items: center;
  background: ${(props) => props.theme.colors.White};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0px 80px;
  width: 100%;

  @media (max-width: ${(props) => props.theme.breakpoints.Tablet}) {
    padding: 0px 60px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.Mobile}) {
    padding: 0px 32px;
  }
`;

const Header = styled.div`
  color: #071058;
  font-family: ${(props) => props.theme.fonts.grotesk};
  font-size: 50px;
  font-weight: 500;
  letter-spacing: -.5px;
  line-height: 102%;
  margin-bottom: 80px;
  text-align: center;
  width: 100%;

  @media (max-width: ${(props) => props.theme.breakpoints.Tablet}) {
    font-size: 40px;
    margin-bottom: 48px;
  }
`;

const BenefitList = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 32px;
  width: 100%;

  @media (max-width: ${(props) => props.theme.breakpoints.Desktop}) {
    grid-template-columns: repeat(3, 33.3%);
  }

  @media (max-width: ${(props) => props.theme.breakpoints.Laptop}) {
    grid-template-columns: repeat(2, 50%);
  }

  @media (max-width: ${(props) => props.theme.breakpoints.Mobile}) {
    grid-template-columns: 50% 50%;
  }
`;

const Benefit = styled.div``;

const StepIcon = styled(GradientIcon)`
  margin-bottom: 28px;

  @media (max-width: ${(props) => props.theme.breakpoints.Desktop}) {
    margin-bottom: 20px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.Mobile}) {
    margin-bottom: 14px;
  }
`;

const BenefitTitle = styled.div`
  color: #071058;
  font-size: 18px;
  line-height: 156%;

  @media (max-width: ${(props) => props.theme.breakpoints.Mobile}) {
    font-size: 16px;
  }
`;

function Benefits() {
  const { brand } = useAppContext();
  const headerText = useMemo(() => {
    switch (brand) {
      case 'unbranded':
        return 'Getting paid earlier means your partners can';
      default:
        return 'Getting paid early means you can';
    }
  }, [brand]);

  const benefitOne = useMemo(() => {
    switch (brand) {
      case 'unbranded':
        return (
          <>
            Overcome gaps
            <br />
            in their cash flow
          </>
        );
      default:
        return (
          <>
            Reduce delays
            <br />
            in your cash flow
          </>
        );
    }
  }, [brand]);

  const benefitTwo = useMemo(() => {
    switch (brand) {
      case 'unbranded':
        return (
          <>
            Invest in and grow
            <br />
            their business
          </>
        );
      default:
        return (
          <>
            Win more business
            <br />
            regardless of payment terms
          </>
        );
    }
  }, [brand]);

  const benefitFive = useMemo(() => {
    switch (brand) {
      case 'unbranded':
        return (
          <>
            Have cash on hand for
            <br />
            unexpected expenses
          </>
        );
      default:
        return (
          <>
            Leverage cash for new
            <br />
            projections and operations
          </>
        );
    }
  }, [brand]);

  return (
    <BenefitsContainer>
      <Header>{headerText}</Header>

      <BenefitList>
        <Benefit>
          <StepIcon height={72} width={72} src="/assets/overcome-gaps.svg" />
          <BenefitTitle>{benefitOne}</BenefitTitle>
        </Benefit>

        <Benefit>
          <StepIcon height={72} width={72} src="/assets/invest-and-grow.svg" />
          <BenefitTitle>{benefitTwo}</BenefitTitle>
        </Benefit>

        <Benefit>
          <StepIcon height={72} width={72} src="/assets/create-predictability.svg" />
          <BenefitTitle>
            Create predictability
            <br />
            in collections
          </BenefitTitle>
        </Benefit>

        <Benefit>
          <StepIcon height={72} width={72} src="/assets/meet-capital-needs.svg" />
          <BenefitTitle>
            Meet your working
            <br />
            capital needs
          </BenefitTitle>
        </Benefit>

        <Benefit>
          <StepIcon height={72} width={72} src="/assets/cash-on-hand.svg" />
          <BenefitTitle>{benefitFive}</BenefitTitle>
        </Benefit>

      </BenefitList>
    </BenefitsContainer>
  );
}

export default Benefits;
