import React, { useEffect, useState } from 'react';
import { useValidation } from '@mesa-labs/mesa-ui';
import styled from 'styled-components';
import { AnimatePresence, motion } from 'framer-motion';

import useGetOnboardingLink from '../../../hooks/useGetOnboardingLink';
import { useAppContext } from '../../../contexts/AppContext';
import { REFERRAL_CODE_LOCAL_STORAGE_KEY } from '../../../hooks/useReferralCode';
import { PROMO_CODE_LOCAL_STORAGE_KEY } from '../../../hooks/usePromoCode';
import { MarketingEvents, trackEvent } from '../../../analytics';

const DesktopFixedNavigationContainer = styled(motion.nav)`
  align-items: center;
  backdrop-filter: blur(8px);
  background: rgba(245, 245, 245, 0.76);
  display: flex;
  justify-content: space-between;
  left: 0px;
  padding: 12px 64px;
  position: fixed;    
  top: 0px;
  width: 100%;
  z-index: 1000;

  @media (max-width: ${(props) => props.theme.breakpoints.Tablet}) {
    display: none;
  }
`;

const Logo = styled.img``;

const Links = styled.div`
  align-items: center;
  display: flex;
  gap: 52px;
  margin-left: auto;
`;

const Link = styled.a`
  font-size: 18px;
  line-height: 100%;
  color: #010844;
  transition: all .25s ease-in-out;

  &:hover {
    text-decoration: underline;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.Laptop}) {
    font-size: 17px;
  }
`;

const GetStartedButton = styled.div`
  align-items: center;
  background: ${(props) => props.theme.colors.Cobalt};
  border-radius: 43px;
  border: 2px solid #4054ED;
  border: 2px solid ${(props) => props.theme.colors.Cobalt};
  color: #FFF;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.16px;
  line-height: 160%;
  padding: 8px 24px;
  transition: background 200ms ease-in-out;

  &:hover {
    background: #162ee9;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.Tablet}) {
    display: none;
  }
`;

type NavigationProps = {
  subRoute: string;
  setSignUpInquiryModalOpen: (open: boolean) => void;
  isVisible: boolean;
};

function DesktopFixedNavigation({ subRoute, setSignUpInquiryModalOpen, isVisible = false }: NavigationProps) {
  const [firstRender, setFirstRender] = useState(true);
  const { partnerId, program, brand } = useAppContext();

  const params = new URLSearchParams(window.location.search);
  const onboardingId = params.get('onboardingId') || '';
  const { results: validationResults } = useValidation({ onboardingId });
  const getOnboardingLink = useGetOnboardingLink({ onboardingId: (validationResults.onboardingId?.isValid && onboardingId) || '' });
  const showNavigation = isVisible && !firstRender;

  // prevent initial render on mount
  // @TODO(taylor) - figure out why IntersectionObserver always returns `true` on
  // first run
  useEffect(() => {
    setTimeout(() => setFirstRender(false), 1000);
  }, []);

  return (
    <AnimatePresence>
      {showNavigation && (
        <DesktopFixedNavigationContainer
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -50 }}
          transition={{
            duration: 0.20,
            type: 'tween',
          }}
        >
          <Logo src="/assets/mesa.svg" />

          <Links>
            {brand !== 'unbranded' && (
              <Link href={`${CONFIG.ui.vendorUIUrl}/faq?partnerId=${partnerId}${subRoute ? `&subRoute=${subRoute}` : ''}${program ? `&programCode=${program}` : ''}`}>
                FAQ
              </Link>
            )}

            {brand !== 'unbranded' && (
              <GetStartedButton
                onClick={() => {
                  localStorage.removeItem(REFERRAL_CODE_LOCAL_STORAGE_KEY);
                  localStorage.removeItem(PROMO_CODE_LOCAL_STORAGE_KEY);
                  trackEvent(MarketingEvents.HeaderGetStartedButtonClick);
                  window.location.assign(getOnboardingLink());
                }}
              >
                Get Started
              </GetStartedButton>
            )}

            {brand === 'unbranded' && (
              <GetStartedButton onClick={() => setSignUpInquiryModalOpen(true)}>Get Demo</GetStartedButton>
            )}
          </Links>
        </DesktopFixedNavigationContainer>
      )}
    </AnimatePresence>
  );
}

export default DesktopFixedNavigation;
