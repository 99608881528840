import { theme } from '@mesa-labs/mesa-ui';
import React, { ReactElement } from 'react';
import styled from 'styled-components';

const NavigationFooterContainer = styled.div<{ textColor?: string }>`
  color: ${(props) => props.textColor || 'black'};
  margin-top: auto;
  width: 100%;
`;

const Footer = styled.div`
  align-items: center;
  border-top: 1px solid #2332A2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
  padding-top: 24px;
  width: 100%;
`;

const SignOut = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding: 10px;
  border: 2px solid #2332A2;
  cursor: pointer;
  border-radius: 6px;
  width: 100%;
`;

const SignOutText = styled.div`
  align-self: flex-start;
`;

const LogoutIcon = styled.img`
  margin-left: auto;
  align-self: flex-end;
`;

type NavigationFooterProps = {
  textColor?: string;
};

function NavigationFooter(props: NavigationFooterProps): ReactElement {
  const {
    textColor,
  } = props;

  const signOutUser = async () => { };

  return (
    <NavigationFooterContainer textColor={textColor}>
      <Footer>
        <SignOut onClick={signOutUser}>
          <SignOutText>Log Out</SignOutText>
          <LogoutIcon src="/assets/logout-icon.svg" />
        </SignOut>
      </Footer>
    </NavigationFooterContainer>
  );
}

NavigationFooter.defaultProps = {
  textColor: theme.colors.White,
};

export default NavigationFooter;
