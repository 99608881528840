import { useCallback } from 'react';
import useReferralCode from './useReferralCode';
import usePromoCode from './usePromoCode';
import { useAppContext } from '../contexts/AppContext';
import { getSubdomain } from '../utils';

type UseGetOnboardingLinkParams = {
  email?: string;
  businessName?: string;
  onboardingId?: string;
};

function useGetOnboardingLink({
  email,
  businessName,
  onboardingId,
}: UseGetOnboardingLinkParams) {
  const referralCode = useReferralCode();
  const promoCode = usePromoCode();
  const subdomain = getSubdomain();

  const { partnerId } = useAppContext();

  const getOnboardingLink = useCallback(() => {
    const onboardingLinkParams = new URLSearchParams();

    if (onboardingId) {
      onboardingLinkParams.append('onboardingId', onboardingId);
    }
    if (email) {
      onboardingLinkParams.append('email', email);
    }
    if (businessName) {
      onboardingLinkParams.append('businessName', businessName);
    }
    if (referralCode) {
      onboardingLinkParams.append('referralCode', referralCode);
    }
    if (promoCode) {
      onboardingLinkParams.append('promoCode', promoCode);
    }
    if (partnerId) {
      onboardingLinkParams.append('partnerId', `${partnerId}`);
    }
    if (subdomain) {
      onboardingLinkParams.append('subdomain', subdomain);
    }

    return `${CONFIG.ui.vendorUIUrl}/sign-up?${onboardingLinkParams.toString()}`;
  }, [referralCode, subdomain, promoCode, partnerId, email, businessName]);

  return getOnboardingLink;
}

export default useGetOnboardingLink;
