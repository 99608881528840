import React, { ReactElement } from 'react';
import { Search } from '@mesa-labs/mesa-ui';
import styled from 'styled-components';

import ExportButton from './ExportButton';

const InlineOptions = styled.div`
  @media (max-width: ${(props) => props.theme.breakpoints.Tablet}) {
    display: none;
  }
  width: 100%;
  justify-content: flex-end;
  display: flex;
  gap: 12px;
`;

const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: 0;
`;

type TableOptionsProps = {
  onDownload: () => Promise<BlobPart | BlobPart[]>;
  exportFileName: string;
};

function TableOptions({
  onDownload,
  exportFileName,
}: TableOptionsProps): ReactElement {
  return (
    <InlineOptions>
      <SearchContainer
        onClick={() => { }}
      >
        <Search
          onChange={() => { }}
          placeholder="Search for invoices"
          onSubmit={() => { }}
          value=""
        />
      </SearchContainer>

      <ExportButton
        onDownload={onDownload}
        fileName={exportFileName}
      />
    </InlineOptions>
  );
}

export default TableOptions;
