import React, { useLayoutEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { GradientIconContainer } from '../../common/GradientIcon';
import { FadeInRight } from '../../common/ScrollAnimations';
import DashboardDemo from '../../common/DashboardDemo';

const DashboardFeaturesContainer = styled.div<{ height: number }>`
  background: ${(props) => props.theme.colors.White};
  display: flex;
  gap: 5%;
  justify-content: space-between;
  margin-bottom: 160px;
  height: ${(props) => props.height}px;
  width: 100%;

  @media (max-width: ${(props) => props.theme.breakpoints.Laptop}) {
    align-items: center;
    flex-direction: column;
    gap: 50px;
    height: 950px;
    margin-bottom: 90px;
    padding: 0px 16px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.Tablet}) {
    display: none;
    height: 0px;
    padding: 0px 40px;
  }
`;

const FeatureList = styled.div`
  color: #071058;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  font-weight: 600;
  justify-content: center;
  line-height: 28px;
  max-width: 25%;

  @media (min-width: ${(props) => props.theme.breakpoints.Desktop}) {
    font-size: 17px;
    max-width: 28%;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.Laptop}) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    max-width: none;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.Mobile}) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const Feature = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  margin-bottom: 40px;

  & span {
    margin-right: 16px;
    white-space: nowrap;
  }
`;

const FeatureConnectorSvg = styled(motion.svg)`
  @media (max-width: ${(props) => props.theme.breakpoints.Laptop}) {
    display: none;
  }
`;

const OverviewDemoContainer = styled.div<{ scale: number }>`
  background: ${(props) => props.theme.colors.White};
  border-radius: 10px;
  border: 1px solid #EEE;
  box-shadow: 0px 11px 32px rgba(55, 58, 85, 0.15);
  height: 1000px;
  pointer-events: none;
  transform: scale(${(props) => props.scale});
  transform-origin: top left;

  @media (max-width: ${(props) => props.theme.breakpoints.Laptop}) {
    transform-origin: top center;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.Tablet}) {
    display: none;
    height: 0px;
  }
`;

function FeatureConnector({ animationDelay }: { animationDelay: number }) {
  return (
    <FeatureConnectorSvg width="306" height="2" viewBox="0 0 306 2" fill="none" xmlns="http://www.w3.org/2000/svg">
      <motion.path
        d="M1 0C0.447715 0 0 0.447715 0 1C0 1.55228 0.447715 2 1 2V0ZM305 2C305.552 2 306 1.55228 306 1C306 0.447715 305.552 0 305 0V2ZM1 2H2V0H1V2ZM4 2H6V0H4V2ZM8 2H10V0H8V2ZM12 2H14V0H12V2ZM16 2H18V0H16V2ZM20 2H22V0H20V2ZM24 2H26V0H24V2ZM28 2H30V0H28V2ZM32 2H34V0H32V2ZM36 2H38V0H36V2ZM40 2H42V0H40V2ZM44 2H46V0H44V2ZM48 2H50V0H48V2ZM52 2H54V0H52V2ZM56 2H58V0H56V2ZM60 2H62V0H60V2ZM64 2H66V0H64V2ZM68 2H70V0H68V2ZM72 2H74V0H72V2ZM76 2H78V0H76V2ZM80 2H82V0H80V2ZM84 2H86V0H84V2ZM88 2H90V0H88V2ZM92 2H94V0H92V2ZM96 2H98V0H96V2ZM100 2H102V0H100V2ZM104 2H106V0H104V2ZM108 2H110V0H108V2ZM112 2H114V0H112V2ZM116 2H118V0H116V2ZM120 2H122V0H120V2ZM124 2H126V0H124V2ZM128 2H130V0H128V2ZM132 2H134V0H132V2ZM136 2H138V0H136V2ZM140 2H142V0H140V2ZM144 2H146V0H144V2ZM148 2H150V0H148V2ZM152 2H154V0H152V2ZM156 2H158V0H156V2ZM160 2H162V0H160V2ZM164 2H166V0H164V2ZM168 2H170V0H168V2ZM172 2H174V0H172V2ZM176 2H178V0H176V2ZM180 2H182V0H180V2ZM184 2H186V0H184V2ZM188 2H190V0H188V2ZM192 2H194V0H192V2ZM196 2H198V0H196V2ZM200 2H202V0H200V2ZM204 2H206V0H204V2ZM208 2H210V0H208V2ZM212 2H214V0H212V2ZM216 2H218V0H216V2ZM220 2H222V0H220V2ZM224 2H226V0H224V2ZM228 2H230V0H228V2ZM232 2H234V0H232V2ZM236 2H238V0H236V2ZM240 2H242V0H240V2ZM244 2H246V0H244V2ZM248 2H250V0H248V2ZM252 2H254V0H252V2ZM256 2H258V0H256V2ZM260 2H262V0H260V2ZM264 2H266V0H264V2ZM268 2H270V0H268V2ZM272 2H274V0H272V2ZM276 2H278V0H276V2ZM280 2H282V0H280V2ZM284 2H286V0H284V2ZM288 2H290V0H288V2ZM292 2H294V0H292V2ZM296 2H298V0H296V2ZM300 2H302V0H300V2ZM304 2H305V0H304V2Z"
        fill="url(#paint0_linear_1226_8546)"
        initial={{
          width: 0,
        }}
        whileInView={{
          width: 'auto',
        }}
        transition={{
          duration: 0.5,
          delay: animationDelay,
        }}
      />
      <defs>
        <linearGradient id="paint0_linear_1226_8546" x1="89.1975" y1="1" x2="273.099" y2="1" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FF8666" />
          <stop offset="1" stopColor="#FF8666" stopOpacity="0" />
        </linearGradient>
      </defs>
    </FeatureConnectorSvg>
  );
}

const AnimationDuration = 2.5;

function DashboardFeatures() {
  const [width, setWidth] = useState(0);

  useLayoutEffect(() => {
    function updateSize() {
      setWidth(window.innerWidth);
    }

    window.addEventListener('resize', updateSize);

    updateSize();

    return () => window.removeEventListener('resize', updateSize);
  }, []);

  const dashboardDemoScale = useMemo(() => Math.max(Math.min(width / 1660, 0.85), 0.65), [width]);
  const dashboardDemoHeight = useMemo(() => 1000 * dashboardDemoScale, [dashboardDemoScale]);

  return (
    <DashboardFeaturesContainer height={dashboardDemoHeight}>
      <FeatureList>
        <FadeInRight>
          <Feature>
            <GradientIconContainer whileHover="hover" height={72} width={72} padding={0}>
              <motion.svg width="34" height="40" viewBox="0 0 34 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M3.66667 33.001L30.3333 33.001C31.8061 33.001 33 31.8071 33 30.3343V11.6676C33 10.1949 31.8061 9.00098 30.3333 9.00098L3.66667 9.00098C2.19391 9.00098 1 10.1949 1 11.6676V30.3343C1 31.8071 2.19391 33.001 3.66667 33.001Z"
                  stroke="#4054ED"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path d="M22.25 38.251H11.75" stroke="#4054ED" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M17 33.001V38.251" stroke="#4054ED" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <motion.path
                  d="M25.3571 16.001H8.64286C8.28782 16.001 8 16.3088 8 16.6885V26.3135C8 26.6932 8.28782 27.001 8.64286 27.001H25.3571C25.7122 27.001 26 26.6932 26 26.3135V16.6885C26 16.3088 25.7122 16.001 25.3571 16.001Z"
                  stroke="#4054ED"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  variants={{
                    hover: {
                      x: [0, 0, 0, 50, -50, 0],
                      opacity: [1, 1, 1, 0, 0, 1],
                    },
                  }}
                  transition={{
                    duration: AnimationDuration,
                  }}
                />
                <motion.rect
                  x="14"
                  y="8"
                  width="6"
                  height="2"
                  fill="#EFF1FD"
                  variants={{
                    hover: {
                      opacity: [1, 1, 0, 0, 0, 1],
                    },
                  }}
                  transition={{
                    duration: AnimationDuration,
                  }}
                />
                <motion.path
                  d="M17 2L17 12"
                  stroke="#FF8666"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  variants={{
                    hover: {
                      y: [0, 0, -100, -100, 20, 0],
                      opacity: [1, 1, 0, 0, 0, 1],
                    },
                  }}
                  transition={{
                    duration: AnimationDuration,
                  }}
                />
                <motion.path
                  d="M20 4L17 1L14 4"
                  stroke="#FF8666"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  variants={{
                    hover: {
                      y: [0, 0, -100, -100, 20, 0],
                      opacity: [1, 1, 0, 0, 0, 1],
                    },
                  }}
                  transition={{
                    duration: AnimationDuration,
                  }}
                />
                <motion.path
                  d="M8 20.001L26 20.001"
                  stroke="#4054ED"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  variants={{
                    hover: {
                      x: [0, 0, 0, 50, -50, 0],
                      opacity: [1, 1, 1, 0, 0, 1],
                    },
                  }}
                  transition={{
                    duration: AnimationDuration,
                  }}
                />
              </motion.svg>
            </GradientIconContainer>

            <span>
              Same-day ACH
            </span>

            <FeatureConnector animationDelay={0} />
          </Feature>
        </FadeInRight>

        <FadeInRight delay={0.1}>
          <Feature>
            <GradientIconContainer whileHover="hover" height={72} width={72} padding={0}>
              <motion.svg
                width="50"
                height="34"
                viewBox="0 0 50 34"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                shapeRendering="geometricPrecision"
                variants={{
                  hover: {
                    y: [3, 0, 3, 0, 3, 3, 3, 3, 3, 3],
                    x: [0, 0, 0, 0, 0, 100, 100, -100, 0, 0],
                    opacity: [1, 1, 1, 1, 1, 0, 0, 1, 1],
                  },
                }}
                transition={{
                  duration: AnimationDuration,
                }}
              >
                <path
                  d="M9.98047 17L13.168 17"
                  stroke="#FF8666"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M6.46094 8.5H12.9688"
                  stroke="#FF8666"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M6.46094 25.5H12.9688"
                  stroke="#FF8666"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M46.1055 8.5H18.4805C17.8937 8.5 17.418 8.9757 17.418 9.5625V24.4375C17.418 25.0243 17.8937 25.5 18.4805 25.5H46.1055C46.6923 25.5 47.168 25.0243 47.168 24.4375V9.5625C47.168 8.9757 46.6923 8.5 46.1055 8.5Z"
                  stroke="#4054ED"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M32.293 21.25C34.6402 21.25 36.543 19.3472 36.543 17C36.543 14.6528 34.6402 12.75 32.293 12.75C29.9458 12.75 28.043 14.6528 28.043 17C28.043 19.3472 29.9458 21.25 32.293 21.25Z"
                  stroke="#4054ED"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M38.668 8.5L47.168 15.9375"
                  stroke="#4054ED"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M38.668 25.5L47.168 18.0625"
                  stroke="#4054ED"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M25.918 8.5L17.418 15.9375"
                  stroke="#4054ED"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M25.918 25.5L17.418 18.0625"
                  stroke="#4054ED"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </motion.svg>

            </GradientIconContainer>
            <span>
              Accelerated Payments
            </span>

            <FeatureConnector animationDelay={0.25} />
          </Feature>
        </FadeInRight>

        <FadeInRight delay={0.2}>
          <Feature>
            <GradientIconContainer whileHover="hover" height={72} width={72} padding={6}>
              <motion.svg width="43" height="43" viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="8" y="1" width="25" height="36" rx="2" stroke="#4054ED" strokeWidth="2" strokeLinejoin="round" />
                <path d="M13 8H27" stroke="#4054ED" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M13 12H27" stroke="#4054ED" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M13 16H27" stroke="#4054ED" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M13 20H23" stroke="#4054ED" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M13 31H18" stroke="#4054ED" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <motion.rect
                  x="32"
                  y="17"
                  width="2"
                  height="17"
                  fill="#EFF1FD"
                  variants={{
                    hover: {
                      opacity: [1, 0, 0, 0, 0, 0, 0, 0, 0, 1, 1],
                    },
                  }}
                  transition={{
                    duration: AnimationDuration,
                  }}
                />
                <motion.circle
                  cx="32.5"
                  cy="25.5"
                  r="5.5"
                  stroke="#FF8666"
                  strokeWidth="2"
                  variants={{
                    hover: {
                      x: [0, -16, -6, -6, -16, -16, -6, -6, -16, -16, 0, 0],
                      y: [0, -18, -18, -14, -14, -10, -10, -6, -6, 0, 0],
                    },
                  }}
                  transition={{
                    duration: AnimationDuration,
                  }}
                />
                <motion.path
                  d="M37 30L42 35"
                  stroke="#FF8666"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  variants={{
                    hover: {
                      x: [0, -16, -6, -6, -16, -16, -6, -6, -16, -16, 0, 0],
                      y: [0, -18, -18, -14, -14, -10, -10, -6, -6, 0, 0],
                    },
                  }}
                  transition={{
                    duration: AnimationDuration,
                  }}
                />
              </motion.svg>
            </GradientIconContainer>

            <span>
              Search Transactions
            </span>

            <FeatureConnector animationDelay={0.50} />
          </Feature>
        </FadeInRight>

        <FadeInRight delay={0.3}>
          <Feature>
            <GradientIconContainer whileHover="hover" height={72} width={72} padding={6}>
              <motion.svg width="38" height="42" viewBox="0 0 38 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="1" y="1" width="29" height="36" rx="2" stroke="#4054ED" strokeWidth="2" strokeLinejoin="round" />
                <motion.path
                  d="M7 21H20"
                  stroke="#4054ED"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  variants={{
                    hover: {
                      pathLength: [1, 0, 1],
                    },
                  }}
                  transition={{
                    duration: AnimationDuration / 3 / 3,
                  }}
                />
                <motion.path
                  d="M7 25H20"
                  stroke="#4054ED"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  variants={{
                    hover: {
                      pathLength: [1, 0, 1],
                    },
                  }}
                  transition={{
                    duration: AnimationDuration / 3 / 3,
                    delay: AnimationDuration / 3 / 3,
                  }}
                />
                <motion.path
                  d="M7 29H13"
                  stroke="#4054ED"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  variants={{
                    hover: {
                      pathLength: [1, 0, 1],
                    },
                  }}
                  transition={{
                    duration: 0.3,
                    delay: (AnimationDuration / 3 / 3) * 2,
                  }}
                />
                <circle cx="11.9993" cy="7.66683" r="2.33333" stroke="#4054ED" strokeWidth="2" />
                <path
                  d="M17 16.0002C17 15.3436 16.8707 14.6935 16.6194 14.0868C16.3681 13.4802 15.9998 12.929 15.5355 12.4647C15.0712 12.0004 14.52 11.6321 13.9134 11.3808C13.3068 11.1296 12.6566 11.0002 12 11.0002C11.3434 11.0002 10.6932 11.1296 10.0866 11.3808C9.47995 11.6321 8.92876 12.0004 8.46447 12.4647C8.00017 12.929 7.63188 13.4802 7.3806 14.0868C7.12933 14.6935 7 15.3436 7 16.0002"
                  stroke="#4054ED"
                  strokeWidth="2"
                />
                <rect x="29" y="21" width="2" height="17" fill="#EEF0FD" />
                <rect x="19" y="38" width="3" height="14" transform="rotate(-90 19 38)" fill="#EAEDFD" />
                <path
                  d="M30 41C33.866 41 37 37.866 37 34C37 30.134 33.866 27 30 27C26.134 27 23 30.134 23 34C23 37.866 26.134 41 30 41Z"
                  stroke="#FF8666"
                  strokeWidth="2"
                  strokeMiterlimit="10"
                />
                <motion.line
                  d="M30 34L33 31"
                  stroke="#FF8666"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  style={{ originX: 1, originY: 1 }}
                  x1="27"
                  x2="30"
                  y1="31"
                  y2="34"
                  variants={{
                    hover: {
                      rotate: [0, 0, 360, 720, 720],
                    },
                  }}
                  transition={{
                    duration: AnimationDuration,
                  }}
                />
                <path
                  d="M28.0918 24H31.91"
                  stroke="#FF8666"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </motion.svg>
            </GradientIconContainer>
            <span>
              5 minute Onboarding
            </span>

            <FeatureConnector animationDelay={0.75} />
          </Feature>
        </FadeInRight>
      </FeatureList>

      <OverviewDemoContainer scale={dashboardDemoScale}>
        <DashboardDemo />
      </OverviewDemoContainer>
    </DashboardFeaturesContainer>
  );
}

export default DashboardFeatures;
