import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { OnboardingSubHeader, PageFooter, Typography } from '@mesa-labs/mesa-ui';
import PageHeader from './PageHeader';

const ElectronicConsentContainer = styled.div`
  ol, ul {
    padding: 0px 32px;
  }

  li {
    padding: 2px 0px;
  }
`;

const ElectronicConsentHeader = styled(Typography.H1)`
  margin-bottom: 8px;
`;

const ElectronicConsentSection = styled(Typography.BodyMedium)`
  margin-bottom: 24px;
`;

const Bold = styled.span`
  font-weight: 600;
`;

const Line = styled.p`
  margin-bottom: 16px;
`;

const LegalContainer = styled.div`
`;

const Content = styled.div`
  max-width: 1360px;
  margin: 0px auto 48px auto;
  padding: 60px 80px 0px 80px;

  @media (max-width: ${(props) => props.theme.breakpoints.Tablet}) {
    padding: 40px;
  }
`;

const LegalTitle = styled(Typography.Title)`
  font-family: ${(props) => props.theme.fonts.grotesk};
  font-size: 32px;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 32px;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  text-align: left;

  &:last-of-type {
    margin-bottom: 0px;
  }
`;

const SectionTitle = styled(Typography.BodySubheader)`
  font-size: 19px;
  line-height: 19px;
  margin-bottom: 8px;
`;

const LinksContainer = styled.div`
  border-radius: 8px;
  box-shadow: 0px 11px 25px rgb(45 49 52 / 7%);
  padding: 48px;
  margin-bottom: 80px;
`;

const LinkContainer = styled.div`
  flex-basis: 50%;
  margin-bottom: 4px;
  text-decoration: underline;

  &:last-of-type {
    margin-bottom: 0px;
  }
`;

function ElectronicConsent(): ReactElement {
  return (
    <LegalContainer>
      <PageHeader />

      <Content>
        <LinksContainer>
          <LegalTitle>Legal Documents</LegalTitle>

          <Section>
            <SectionTitle>Terms</SectionTitle>

            <LinkContainer>
              <Link to="/legal/terms-of-service">Terms of Service</Link>
            </LinkContainer>
          </Section>

          <Section>
            <SectionTitle>Policies</SectionTitle>

            <LinkContainer>
              <Link to="/legal/privacy-policy">Privacy Policy</Link>
            </LinkContainer>

            <LinkContainer>
              <Link to="/legal/electronic-consent">Eletronic Consent</Link>
            </LinkContainer>
          </Section>
        </LinksContainer>

        <ElectronicConsentContainer>
          <ElectronicConsentHeader>CONSENT TO RECEIVE ELECTRONIC DISCLOSURES</ElectronicConsentHeader>

          <OnboardingSubHeader>
            Date of Last Revision: Nov. 4, 2021
          </OnboardingSubHeader>

          <ElectronicConsentSection>
            <Line>
              In connection with obtaining services through the platform administered
              by Mesa Financial, Inc. (“Mesa Financial”, “we”, “us”), you consent to receive and
              view communications, disclosures, notices, statements, policies,
              agreements and other communications we are required by law to provide to
              you or may otherwise provide to you for any product or service you
              obtain from us (collectively, “Disclosures”) relating to your eligible
              account on the Mesa platform (“Account”) electronically by any of the
              following means:
            </Line>
            <ul>
              <li>
                Text to your mobile phone number which may include a link to a new
                Disclosure on our website (“Website”);
              </li>
              <li>
                To your email; or
              </li>
              <li>
                Notifications from our website (“Application”).
              </li>
            </ul>
          </ElectronicConsentSection>

          <ElectronicConsentSection>
            <Line>
              Your consent covers all Disclosures relating to any product we offer
              through the website at joinmesa.com and remains in effect until you
              give us notice that you are withdrawing it.  Delivery by any of these
              means will constitute proper notice to you under applicable law.
            </Line>
          </ElectronicConsentSection>

          <ElectronicConsentSection>
            <Line>
              You acknowledge that Disclosures will include, but may not be limited
              to, the following:
            </Line>
            <ul>
              <li>
                The website, Privacy Policy, and Terms of Service and other
                documentation we provide through the website (“Policies and
                Agreements”);
              </li>
              <li>
                Disclosures and/or amendments we may provide you under our Policies and
                Agreements;
              </li>
              <li>
                Balance, activity and any other information on your Account;
              </li>
              <li>
                Statements, receipts, confirmations, authorizations and transaction
                history for your Account;
              </li>
              <li>
                Disclosures regarding the resolution of any claimed error on your statements; and
              </li>
              <li>
                Disclosures required or permitted by law or regulation.
              </li>
            </ul>
          </ElectronicConsentSection>

          <ElectronicConsentSection>
            <Bold>Your Right to Revoke Consent: </Bold>
            Your consent is effective until further notice by us or until you
            revoke your consent to receive electronic Disclosures. You may revoke
            your consent to receive electronic Disclosures at any time either by
            clicking the “Contact Us” button at the bottom of this page or
            submitting your request in writing to: Legal Department, Mesa Financial,
            Inc. 1905 15th St. #338 Boulder, CO 80306.  Your withdrawal of consent
            will become effective after we have had a reasonable opportunity to act
            upon it.
          </ElectronicConsentSection>

          <ElectronicConsentSection>
            If you do not consent or if you withdraw your consent, we reserve the
            right to refuse to accept your Account application, to cancel your
            Account, place your Account on inactive status, or to provide a paper
            copy of Disclosures. If you request a paper copy of a Disclosure within
            180 days of the date of the Disclosure and we elect to send you a paper
            copy, we will waive our standard Disclosure Request Fee for the first
            two requests. After that, any additional Disclosure Requests may be
            subject to fees. We will only provide paper copies upon your request if
            your current mailing address is in your Account profile.
          </ElectronicConsentSection>

          <ElectronicConsentSection>
            <Line>
              <Bold>System Requirements: </Bold>
              In order to receive Disclosures, whether by text or email, you need
              to have a means of printing or storing them. So, in addition to
              having and email address and phone number you must have the
              following:
            </Line>
            <ul>
              <li>
                a computer or mobile device with Internet connection;
              </li>
              <li>
                a current web browser with cookies enabled;
              </li>
              <li>
                a valid email address on file in your Account profile;
              </li>
              <li>
                the ability to store or print the Disclosures; and
              </li>
              <li>
                if you use a spam blocker, you must add @joinmesa.com to your email
                address book or whitelist
              </li>
            </ul>
          </ElectronicConsentSection>

          <ElectronicConsentSection>
            <Line>
              By giving your consent you are confirming that you have access to the
              necessary equipment and are able to receive, open, and print or
              download a copy of any Disclosure for your records.  We reserve the
              right to change these System Requirements and will provide you with a
              Disclosure when we make a material change to the System Requirements.
            </Line>
          </ElectronicConsentSection>

          <ElectronicConsentSection>
            <Line>
              <Bold>Receiving Texts and Emails: </Bold>
              In order to receive Disclosures, you must ensure that the primary
              mobile phone and/or email address that you provide us is your valid,
              current phone number or email address, and you are able to receive at
              that address texts or email messages containing Disclosures including
              attached electronic documents and that such Disclosures, including
              portions that are attached documents are available for viewing and
              storing or printing by you. You agree to promptly update your email
              address by updating your Account profile if your email address
              changes. You acknowledge that our ability to notify you of the
              availability of your Disclosures is contingent on the validity of
              mobile phone number and email address in our records. If your mobile
              phone or email address is no longer valid, we reserve the right to
              determine your Account is inactive or take other actions as set forth
              in the Terms of Service. You will not be able to conduct any
              transactions in your Account until you update your mobile phone or
              email address in your Account profile.
            </Line>
          </ElectronicConsentSection>

          <ElectronicConsentSection>
            <Line>
              <Bold>Reservation of Rights: </Bold>
              We reserve the right to provide you with any Disclosure in writing,
              rather than electronically, or to withdraw the right to receive
              Disclosures electronically at any time. You agree to maintain on file
              with us your current street address and to promptly update your
              address in the event it changes by updating your Account profile.
              Although we may waive our fee for delivery of paper Disclosures, we
              reserve the right to charge the Disclosure Request Fee and to
              increase this fee in our discretion.
            </Line>
          </ElectronicConsentSection>

          <ElectronicConsentSection>
            <Line>
              <Bold>Communications in Writing: </Bold>
              We recommend that you print a copy of this Consent and any Disclosure
              that you view electronically for your records as the Disclosure may
              not be accessible online at a later date.  All Disclosures from us to
              you will be considered &qout;in writing&ldquo; and shall have the
              same meaning and effect as a paper Disclosure. You acknowledge and
              agree that Disclosures are considered received by you within 24 hours
              of the time posted to the Website, or within 24 hours of the time
              emailed or sent via text to you unless we receive notice that the
              Disclosure was not delivered.
            </Line>
          </ElectronicConsentSection>

          <ElectronicConsentSection>
            <Line>
              <Bold>General: </Bold>
              You understand and agree that we are responsible for sending the
              Disclosures to you electronically by email or text to the address in
              your Account profile or through the Service. We are not responsible
              for any delay or failure in your receipt of the email or text notices
              and whether or not you choose to view the Disclosure, subject to your
              right to revoke your consent to receive Disclosures electronically.
            </Line>
          </ElectronicConsentSection>

          <ElectronicConsentSection>
            <Line>
              <Bold>Contact Us: </Bold>
              Except as otherwise provided by law or other agreement between you
              and us, you may provide us with notices regarding your Accounts by
              emailing us at support@joinmesa.com.
            </Line>
          </ElectronicConsentSection>
        </ElectronicConsentContainer>

      </Content>

      <PageFooter />
    </LegalContainer>
  );
}

export default ElectronicConsent;
