import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const PageHeaderContainer = styled.div`
  align-items: center;
  background: ${(props) => props.theme.colors.White};
  border-bottom: 1px solid ${(props) => props.theme.colors.CoolGrey};
  display: flex;
  padding: 20px 0px;
  width: 100%;
`;

const NavLinkContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 0px auto;
  width: 1200px;
  padding: 0px 50px;

  @media (max-width: ${(props) => props.theme.breakpoints.Mobile}) {
    flex-direction: column;
    gap: 12px;
  }
`;

const NavLogo = styled.img`
  width: 80px;
`;

const NavLinks = styled.div`
  align-items: center;
  display: flex;
`;

const NavLinkExternal = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${(props) => props.theme.colors.Midnight};
  font-weight: 500;
  cursor: pointer;
`;

const NavLink = styled(Link)`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${(props) => props.theme.colors.Midnight};
  font-weight: 500;
  cursor: pointer;
`;

const NavSeparator = styled.div`
  margin: 0px 12px;
`;

function PageHeader(): ReactElement {
  return (
    <PageHeaderContainer>
      <NavLinkContainer>
        <a href="/">
          <NavLogo src="/assets/mesa.svg" />
        </a>

        <NavLinks>
          <NavLink to="/">Home</NavLink>
          <NavSeparator>|</NavSeparator>
          <NavLinkExternal href={`${CONFIG.ui.vendorUIUrl}/sign-in`}>Sign In</NavLinkExternal>
        </NavLinks>
      </NavLinkContainer>
    </PageHeaderContainer>
  );
}

export default PageHeader;
