import React from 'react';
import styled from 'styled-components';

const GetStartedCardContainer = styled.div``;

const Button = styled.div`
  background: ${(props) => props.theme.colors.White};
  border-radius: 40px;
  border: 2px solid #4054ED;
  box-shadow: 0px -2.663px 5.325px 0px rgba(64, 84, 237, 0.20) inset, 0px 0.888px 1.775px 0px rgba(64, 84, 237, 0.50);  
  color: ${(props) => props.theme.colors.Cobalt};
  cursor: pointer;
  display: flex;
  font-size: 20px;
  font-weight: 600;
  gap: 28px;
  justify-content: space-between;
  line-height: 21px;
  padding: 24px 32px;
  transition: box-shadow 200ms ease-in-out;
  width: fit-content;

  &:hover {
    box-shadow: 0px -7.663px 11.325px 0px rgba(64, 84, 237, 0.20) inset, 0px 0.888px 1.775px 0px rgba(64, 84, 237, 0.50);  
  }
`;

const GetStartedIcon = styled.img`
  transform: scale(1.6);
`;

type GetStartedCardProps = {
  dataCy: string;
  onClick: () => void;
};

function GetStartedCard({ dataCy, onClick }: GetStartedCardProps) {
  return (
    <GetStartedCardContainer>
      <Button data-cy={dataCy} onClick={onClick}>
        <span>
          Get Started
        </span>
        <GetStartedIcon src="/assets/right-arrow-icon.svg" />
      </Button>
    </GetStartedCardContainer>
  );
}

export default GetStartedCard;
