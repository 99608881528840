import React, {
  ReactElement,
  useMemo,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';
import { Notification } from '@mesa-labs/mesa-ui';

import Hero from './sections/Hero';
import DashboardFeatures from './sections/DashboardFeatures';
import PaymentSteps from './sections/PaymentSteps';
import AchLegacy from './sections/AchLegacy';
import Benefits from './sections/Benefits';
import SupportedBusinesses from './sections/SupportedBusinesses';
import EasyOnboarding from './sections/EasyOnboarding';
import Footer from './sections/Footer';
import LearnMore from './sections/LearnMore';
import ContactUsModal from './sections/ContactUsModal';
import useReferralCode from '../../hooks/useReferralCode';
import CustomerReviews from './sections/CustomerReviews';
import usePromoCode from '../../hooks/usePromoCode';
import useSubRoute from '../../hooks/useSubRoute';
import { useAppContext } from '../../contexts/AppContext';
import useScrollObserver from '../../hooks/useScrollObserver';
import DesktopFixedNavigation from './sections/DesktopFixedNavigation';

const ModalContainer = styled.div`
  position: relative;
  z-index: 3;
`;

const NotificationContainer = styled.div`
  left: 50%;
  position: fixed;
  top: 80px;
  transform: translateX(-50%);
  z-index: 2;
`;

const DefaultLayout = styled.div`
  font-size: 16px;
  margin: 0px auto;
  max-width: 1600px;
  padding: 40px;
  z-index: 1;

  @media (max-width: ${(props) => props.theme.breakpoints.Tablet}) {
    padding: 0px;
  }
`;

const GreyLayout = styled.div`
  background: #F2F3F9;
  overflow: hidden;
  margin: 0px auto 50px auto;
  padding: 120px 50px 0px 50px;
  width: 100%;

  & > div {
    max-width: 1600px;
    z-index: 1;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.Tablet}) {
    padding: 50px 32px 0px 32px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.Mobile}) {
    padding: 32px 16px 0px 16px;
  }
`;

const TopGradient = styled.img`
  position: absolute;
  right: 0px;

  @media (max-width: ${(props) => props.theme.breakpoints.Tablet}) {
    display: none;
  }
`;

function Home(): ReactElement {
  const { brand } = useAppContext();
  const subRoute = useSubRoute();
  const preopenModal = useMemo(() => subRoute === 'request-access', [subRoute]);

  const heroDesktopNavigationRef = useRef<HTMLDivElement>(null);
  const isFixedNavigationVisible = !useScrollObserver({ ref: heroDesktopNavigationRef, threshold: 0, continuallyObserve: true });

  useReferralCode();
  usePromoCode();

  const [signUpInquiryModalOpen, setSignUpInquiryModalOpen] = useState(preopenModal);
  const [signUpInquiryModalStatus, setSignUpInquiryModalStatus] = useState('ready');
  const [signUpInquiryModalTitle, setSignUpInquiryModalTitle] = useState('Success');
  const [signUpInquiryModalStatusMessage, setSignUpInquiryModalStatusMessage] = useState('');

  return (
    <div>
      <NotificationContainer>
        {signUpInquiryModalStatus !== 'ready' && (
          <Notification
            type={signUpInquiryModalStatus as any}
            message={signUpInquiryModalStatusMessage}
            onClick={() => setSignUpInquiryModalStatus('ready')}
            customTitle={signUpInquiryModalTitle}
          />
        )}
      </NotificationContainer>
      <TopGradient src="/assets/top-page-gradient.svg" />

      <ModalContainer>
        <ContactUsModal
          onClose={() => {
            setSignUpInquiryModalOpen(false);
          }}
          setStatus={(status: string) => setSignUpInquiryModalStatus(status)}
          setMessage={(message: string) => setSignUpInquiryModalStatusMessage(message)}
          setTitle={(title: string) => setSignUpInquiryModalTitle(title)}
          open={signUpInquiryModalOpen}
        />
      </ModalContainer>

      <DesktopFixedNavigation
        subRoute={subRoute}
        setSignUpInquiryModalOpen={setSignUpInquiryModalOpen}
        isVisible={isFixedNavigationVisible}
      />

      <DefaultLayout>
        <Hero
          setSignUpInquiryModalOpen={setSignUpInquiryModalOpen}
          subRoute={subRoute}
          heroDesktopNavigationRef={heroDesktopNavigationRef}
        />
        <DashboardFeatures />
        <PaymentSteps />
        <AchLegacy />
        <Benefits />
        <SupportedBusinesses />
      </DefaultLayout>

      <GreyLayout>
        <CustomerReviews />
        <EasyOnboarding />
        {brand !== 'unbranded' && (
          <LearnMore />
        )}
      </GreyLayout>

      <DefaultLayout>
        <Footer />
      </DefaultLayout>
    </div>
  );
}

export default Home;
